import React, { Component } from "react"
import { connect } from "react-redux"
import {
  fetchAllStationsData,
  logScreenEnterance,
  resetUpdatePassword,
  updateUserPassword,
  LogoutUser,
} from "../../../../../actions"
import { validation } from "../../../../../utils"
import { DisEnbPendBtn, InputFiled, Progressbar } from "../../../../common"
import VirtalKeyboard from "../../../../common/VirtualKeyboard"
import ErrorInfoModal from "../../../../Modals/ErrorInfoModal"
import { FixedHeader } from "../../../../reduxified"
import FixedFooter from "../../../../reduxified/FixedFooter"
import { privacyPolicy } from "../../SignUpScreens/Step2Screen/privacyPolicy"
import TermsAndPolicyCb from "../../SignUpScreens/Step2Screen/TermsAndPolicyCb"
import { termsOfUse } from "../../SignUpScreens/Step2Screen/termsOfUse"
import Modal from "react-modal"
import { OrangeButton } from "../../../../common/OrangeButton"
import "./style.css"

class PasswordUpdateScreen extends Component {
  constructor(props) {
    super(props)

    this.INIT = {
      pwd: "",
      rpwd: "",
      cmpName: "",

      pwdRef: null,
      rpwdRef: null,
      cmpNameRef: null,

      activeInput: true,
      activeInputName: "pwd",

      isCheckBoxChecked: false,

      pwdErr: false,
      rpwdErr: false,
      isSubmitBtnDisabled: true,
      errorsTimeout: null,

      showLegalInformationModal: false,
      largeModalTitle: "",
      largeModalContent: "",

      runValidationsTimeOut: null,
      showErrorModal: false,

      inputPasswordType: "password",
      inputRetypePasswordType: "password",

      serviceName: "",
    }

    this.state = this.INIT
  }

  componentDidMount() {
    // this.state = this.INIT
    const { authenticated } = this.props
    if (!authenticated) {
      this.props.LogoutUser()
      this.props.history.push("")
    }
    this.props.logScreenEnterance("PASSWORD_UPDATE_SCREEN")
    this.setState({ serviceName: this.props.history.location.state })
    this.props.fetchAllStationsData()
  }

  async componentWillUnmount() {
    await this.props.resetUpdatePassword()
    this.setState(this.INIT)
  }
  componentDidUpdate() {
    const {
      authenticated,
      isPasswordTemporary,
      isRefillDetails,
      isUpdatedUserPassword,
      isErrorOnUpdateUserPassword,
    } = this.props

    if (!authenticated) {
      this.props.LogoutUser()
      this.props.history.push("")
    }

    if (
      !isPasswordTemporary ||
      (isUpdatedUserPassword && !isErrorOnUpdateUserPassword)
    ) {
      if (isRefillDetails) {
        this.props.history.push("CompleteUserDetails", this.state.serviceName)
      } else {
        if (this.state.serviceName === "laundry") {
          this.props.history.push("UserMenuScreen")
        }
        if (this.state.serviceName === "rent") {
          this.props.history.push("RentLockerMenuScreen")
        }
        if (this.state.serviceName === "lockerDelivery") {
          this.props.history.push("LockerToLockerDeliveryScreen/info")
        }
      }
    }

    if (isErrorOnUpdateUserPassword) {
      this.setState({ showErrorModal: true })
      this.props.resetUpdatePassword()
    }
  }

  onKeyboardTextChange(text) {
    const { activeInputName, errorsTimeout } = this.state

    if (errorsTimeout) {
      clearTimeout(errorsTimeout)
    }

    this.setState({
      [activeInputName]: text,
      pwdErr: false,
      rpwdErr: false,
      errorsTimeout: null,
    })

    this.runValidationsWithTimoutDebounce()
  }

  onRefReady(refName, ref) {
    if (!this.state[refName]) {
      this.setState({ [refName]: ref })
    }
  }

  onInputFocusChange(refName, isFocused) {
    if (isFocused) {
      this.setState({
        activeInput: this.state[refName],
        activeInputName: refName.replace("Ref", ""),
      })
    } else {
      if (this.state.activeInput === this.state[refName])
        this.setState({ activeInput: null })
    }
  }

  onSubmitClick() {
    const { authenticated, userId, isPasswordTemporary } = this.props

    if (authenticated && isPasswordTemporary) {
      console.log("PASSWORD IS TEMP")

      const userdata = {
        userId,
        password: this.state.pwd,
      }

      this.props.updateUserPassword(userdata)
    }
  }

  runValidations() {
    const { pwd, rpwd, isCheckBoxChecked } = this.state

    const isPwdInputValid = validation.PwdStrong(pwd)
    const isRPwdInputValid = validation.PwdVerify(pwd, rpwd)

    const isEmptyPwd = pwd.length < 1
    const isEmptyRpwd = rpwd.length < 1

    const isSubmitBtnDisabled =
      !isPwdInputValid || !isRPwdInputValid || !isCheckBoxChecked
    const pwdErr = !isPwdInputValid && !isEmptyPwd
    const rpwdErr = !isRPwdInputValid && !isEmptyRpwd

    if (!isSubmitBtnDisabled) {
      this.setState({
        isSubmitBtnDisabled,
        pwdErr,
        rpwdErr,
        runValidationsTimeOut: null,
      })
    } else {
      const errorsTimeout = setTimeout(
        () =>
          this.setState({
            isSubmitBtnDisabled,
            pwdErr,
            rpwdErr,
            runValidationsTimeOut: null,
          }),
        800
      )

      this.setState({ errorsTimeout })
    }
  }

  onViewPasswordClick() {
    const { inputPasswordType } = this.state
    if (inputPasswordType === "password") {
      this.setState({ inputPasswordType: "text" })
    } else {
      this.setState({ inputPasswordType: "password" })
    }
  }

  onViewPasswordRetypeClick() {
    const { inputRetypePasswordType } = this.state
    if (inputRetypePasswordType === "password") {
      this.setState({ inputRetypePasswordType: "text" })
    } else {
      this.setState({ inputRetypePasswordType: "password" })
    }
  }
  onTermsLinkClick() {
    this.setState({
      largeModalTitle: "תנאי שימוש",
      largeModalContent: termsOfUse,
      showLegalInformationModal: true,
    })
  }

  onPrivacyPolicyClick() {
    this.setState({
      largeModalTitle: "מדיניות הפרטיות",
      largeModalContent: privacyPolicy,
      showLegalInformationModal: true,
    })
  }

  onModalFinished() {
    this.setState({
      showLegalInformationModal: false,
    })
  }

  onCheckboxChange(newState) {
    let { isSubmitBtnDisabled } = this.state

    if (!newState) {
      isSubmitBtnDisabled = true
    }

    this.setState({
      isCheckBoxChecked: newState,
      isSubmitBtnDisabled,
    })

    this.runValidationsWithTimoutDebounce()
  }

  onInputFieldChange(inputName, val) {
    this.setState({ [inputName]: val })

    this.runValidationsWithTimoutDebounce()
  }

  runValidationsWithTimoutDebounce() {
    let { runValidationsTimeOut } = this.state

    if (runValidationsTimeOut) {
      clearTimeout(runValidationsTimeOut)
    }

    runValidationsTimeOut = setTimeout(() => this.runValidations(), 500)

    this.setState({ runValidationsTimeOut })
  }

  onCloseErrorModal() {
    this.setState(this.INIT)
    this.props.resetUpdatePassword()
  }
  newlineText(text) {
    if (text === undefined || text === null || text.length <= 0) {
      return text
    }
    return text.split("\n").map((str) => <p>{str}</p>)
  }

  render() {
    const {
      pwd,
      rpwd,
      activeInputName,
      isCheckBoxChecked,
      pwdErr,
      rpwdErr,
      isSubmitBtnDisabled,
      showLegalInformationModal,
      largeModalContent,
      largeModalTitle,
      showErrorModal,
      inputPasswordType,
      inputRetypePasswordType,
    } = this.state

    const {
      dirRtl,
      welcome_title_text,
      const_password_title,
      ok_text,
      star_pwd_input_text_hint,
      retyped_pwd_input_text_hint,
      termsCbTranslation,
      validationtext,
      close_text,
      isLoadingRequest,
      password_update_error_title,
      password_update_error_text,
      isRefillDetails,
    } = this.props

    return (
      <div className="sus2s-wrapper">
        <FixedHeader
          title={welcome_title_text}
          buttonsFlag="passwordUpdateScreen"
        />

        <div className="sus2s-body" dir={dirRtl ? "rtl" : "ltr"}>
          <div className="sus2s-frame-container">
            <div className="sus2s-frame-wrapper">
              <div className="change-password-title">
                {const_password_title}
              </div>
              <div className="sus2s-frame-inner-wrapper">
                <InputFiled
                  value={pwd}
                  type={inputPasswordType}
                  onViewPasswordClick={(e) => this.onViewPasswordClick()}
                  showEye
                  placeholder={star_pwd_input_text_hint}
                  onRefReady={(ref) => this.onRefReady("pwdRef", ref)}
                  onChange={(e) =>
                    this.onInputFieldChange("pwd", e.target.value)
                  }
                  onFocusChanged={(isFocused) =>
                    this.onInputFocusChange("pwdRef", isFocused)
                  }
                  displayErr={pwdErr}
                  dirRtl={dirRtl}
                  validationtext={validationtext.password}
                  autoFocus
                  step1
                />
                <InputFiled
                  value={rpwd}
                  type={inputRetypePasswordType}
                  onViewPasswordClick={(e) => this.onViewPasswordRetypeClick()}
                  showEye
                  placeholder={retyped_pwd_input_text_hint}
                  onRefReady={(ref) => this.onRefReady("rpwdRef", ref)}
                  onChange={(e) =>
                    this.onInputFieldChange("rpwd", e.target.value)
                  }
                  onFocusChanged={(isFocused) =>
                    this.onInputFocusChange("rpwdRef", isFocused)
                  }
                  displayErr={rpwdErr}
                  mandatory
                  dirRtl={dirRtl}
                  icon="mini_lock"
                  validationtext={validationtext.passwordVerification}
                  step1
                />
              </div>

              <TermsAndPolicyCb
                isCheckBoxChecked={isCheckBoxChecked}
                onCheckboxChange={(isCheck) => this.onCheckboxChange(isCheck)}
                onTermsClick={() => this.onTermsLinkClick()}
                onPrivacyClick={() => this.onPrivacyPolicyClick()}
                translation={termsCbTranslation}
              />

              <div className="sus2s-frame-inner-wrapper">
                <DisEnbPendBtn
                  extraClass="sus2s-details-single-btn"
                  onClick={(e) => this.onSubmitClick()}
                  isDisabled={isSubmitBtnDisabled}
                  isPending={isLoadingRequest}
                >
                  {ok_text}
                </DisEnbPendBtn>
              </div>
            </div>
          </div>
        </div>
        <VirtalKeyboard
          currentValue={this.state[activeInputName]}
          onKeyboardInputChange={(txt) => this.onKeyboardTextChange(txt)}
          show={true}
          lang="en"
          closeOnEnter
          blockLangSwitch
        />
        <Modal
          isOpen={showLegalInformationModal}
          onRequestClose={() => this.onModalFinished()}
          className="react-modal-content big-react-modal"
          overlayClassName="modal-overlay"
        >
          <div className="lim-details-container">
            <p className="lim-label-extra-class">{largeModalTitle}</p>
            <iframe
              src="https://done.co.il/policy/"
              className="iframe_policy"
            ></iframe>
            <OrangeButton
              extraClass="lim-close-btn"
              onClick={() => this.onModalFinished()}
            >
              חזרה
            </OrangeButton>
          </div>
        </Modal>

        <ErrorInfoModal
          dirRtl={dirRtl}
          show={showErrorModal}
          title={password_update_error_title}
          text={password_update_error_text}
          onModalCloseClick={() => this.onCloseErrorModal()}
          closeTrnsl={close_text}
        />

        <FixedFooter history={this.props.history} hideBack={true} />
      </div>
    )
  }
}

const mapStateToProps = ({
  registration,
  userLogin,
  bgService,
  translate,
  recreatePassword,
}) => {
  const { mobile, email, firstName, lastName, allStationsData } = registration
  const {
    authenticated,
    userId,
    role,
    firstName: fName,
    lastName: lName,
    isPasswordTemporary,
    isRefillDetails,
    email: userEmail,
    mobile: userMobile,
  } = userLogin
  const { stationId } = bgService
  const {
    isUpdatedUserPassword,
    isErrorOnUpdateUserPassword,
    isLoadingRequest,
  } = recreatePassword

  const {
    dirRtl,
    welcome_title_text,
    const_password_title,
    ok_text,
    star_pwd_input_text_hint,
    retyped_pwd_input_text_hint,
    star_cmpName_input_text_hint,
    im_approving,
    terms_of_use,
    and_the_privacy_policy,
    validation_empty_text,
    validation_mobile_text,
    validation_password_text,
    validation_password_verification_text,
    close_text,
    reset_pwd_modal_title,
    reset_pwd_modal_body,
    password_update_error_title,
    password_update_error_text,
  } = translate
  const validationtext = {
    empty: validation_empty_text,
    mobile: validation_mobile_text,
    password: validation_password_text,
    passwordVerification: validation_password_verification_text,
  }
  const termsCbTranslation = {
    im_approving,
    terms_of_use,
    and_the_privacy_policy,
  }

  const currentStationIndex = allStationsData.indexOf(
    allStationsData.find((station) => station.stationId === stationId)
  )

  return {
    mobile,
    email,
    firstName,
    lastName,
    authenticated,
    stationId,
    userId,
    role,
    fName,
    lName,
    isPasswordTemporary,
    isRefillDetails,
    userEmail,
    userMobile,
    allStationsData,
    currentStationIndex,

    dirRtl,
    welcome_title_text,
    const_password_title,
    ok_text,
    star_pwd_input_text_hint,
    retyped_pwd_input_text_hint,
    star_cmpName_input_text_hint,
    termsCbTranslation,
    validationtext,
    close_text,
    reset_pwd_modal_title,
    reset_pwd_modal_body,

    isUpdatedUserPassword,
    isErrorOnUpdateUserPassword,
    isLoadingRequest,
    password_update_error_title,
    password_update_error_text,
  }
}

export default connect(mapStateToProps, {
  updateUserPassword,
  fetchAllStationsData,
  logScreenEnterance,
  resetUpdatePassword,
  LogoutUser,
})(PasswordUpdateScreen)
