import React, { Component } from "react"
import { connect } from "react-redux"
import {
  fetchAllLockersStatuses,
  fetchOrderTypes,
  LogoutUser,
  logScreenEnterance,
  onDriverReturnesCleanOrder,
  onDriverReturnesCleanOrderCancel,
  onGetReadyOrders,
  openAndListenToSpecificLockerLock,
  setPageAsVisited,
  cleanPutToLockerError,
  cleanCancelPutToLockerError,
} from "../../../../actions"
import { getOrderTypeTextFromOrderType } from "../../../../utils/OrderType"
import { WhiteButton } from "../../../common"
import OrderBarcodeReader from "../../../common/OrderBarcodeReader"
import ErrorInfoModal from "../../../Modals/ErrorInfoModal"
import LoadingInfoModal from "../../../Modals/LoadingInfoModal"
import WaitingForLockerLock from "../../../Modals/WaitingForLockerLock"
import WarningInfoModal from "../../../Modals/WarningInfoModal"
import { FixedHeader, LockerCells } from "../../../reduxified"
import ReadyOrdersDetailsList from "./ReadyOrdersDetailsList"
import FixedFooter from "../../../reduxified/FixedFooter"
import "./style.scss"
import CheckLockerModal from "../../../Modals/CheckLockerModal"
import ManualPackageSearchModal from "../../../Modals/ManualPackageSearchModal"
import { findOrderByBarcodeAndRole } from "../../../../utils/findOrderByBarcodeAndRole"

class LoadCleanOrdersScreen extends Component {
  constructor(props) {
    super(props)

    this.INIT_STATE = {
      selectedOrderId: null,
      selectedOrderData: null,
      lockerNum: null,
      lockerId: null,
      currentlyOpeningALocker: false,
      nextMaxAttempts: 0,

      enableUnresponsiveDoorBtn: false,
      lastOpenDoorSuccessLockerNum: null,
      barcode: "",
      showWarningModal: false,
      showWarning2Modal: false,
      showWarning3Modal: false,
      showWaitingForLockModal: false,
      showOpeningLockerFailModal: false,
      showCheckLockerPopup: false,
      showErrorPutToLocker: false,
      showErrorCancelPutToLocker: false,
      showManualPackageSearch: false,
      showNotFoundBarcodeModal: false,
    }

    this.state = this.INIT_STATE
    this.refOnSelectOrder = React.createRef()

    this.stopBarcodeScan = this.stopBarcodeScan.bind(this)
  }

  componentDidMount() {
    const { authenticated } = this.props
    if (!authenticated) {
      this.props.LogoutUser()
      this.props.history.push("")
    }

    const { stationId } = this.props

    this.props.fetchOrderTypes()
    this.props.onGetReadyOrders(stationId)
    this.props.setPageAsVisited("LoadCleanOrdersScreen")
    this.props.logScreenEnterance("DRIVER_LOAD_CLEAN_ORDERS_SCREEN")
  }

  stopBarcodeScan() {
    const {
      showWarningModal,
      showWarning2Modal,
      showWarning3Modal,
      showWaitingForLockModal,
      showOpeningLockerFailModal,
      showCheckLockerPopup,
      showErrorPutToLocker,
      showErrorCancelPutToLocker,
      showManualPackageSearch,
      showNotFoundBarcodeModal,
      currentlyOpeningALocker,
    } = this.state

    return (
      showWarningModal ||
      showWarning2Modal ||
      showWarning3Modal ||
      showWaitingForLockModal ||
      showOpeningLockerFailModal ||
      showCheckLockerPopup ||
      showErrorPutToLocker ||
      showErrorCancelPutToLocker ||
      showManualPackageSearch ||
      showNotFoundBarcodeModal ||
      currentlyOpeningALocker
    )
  }

  async componentWillReceiveProps(newProps) {
    const {
      openedLockersArr,
      errorLockersArr,
      openingAttempsMap,
      recentClosedLockersArr,
      recentOpenAttemptLockerNum,
      stationId,
    } = newProps

    const {
      lockerNum,
      selectedOrderId,
      lockerId,
      nextMaxAttempts,
      lastOpenDoorSuccessLockerNum,
    } = this.state

    if (!lockerNum) {
      return
    }

    if (lockerNum !== recentOpenAttemptLockerNum) {
      return
    }

    const openingDoorSuccess = openedLockersArr.includes(lockerNum)
    const openingErrorOccurred = errorLockersArr.includes(lockerNum)
    const orderWasLoaded = recentClosedLockersArr.includes(lockerNum)
    const openAttemptsNum = openingAttempsMap[lockerNum] || 0

    if (newProps.failPutToLocker) {
      this.setState({ showErrorPutToLocker: true })
      this.props.cleanPutToLockerError()
      return
    }

    if (newProps.failCancelPutToLocker) {
      this.setState({ showErrorCancelPutToLocker: true })
      this.props.cleanCancelPutToLockerError()
      return
    }

    if (orderWasLoaded) {
      console.log("ORDER WAS LOADED")

      if (newProps.closedLocker !== this.props.closedLocker) {
        if (newProps.closedLocker.isOccupied === 0) {
          this.setState({ showCheckLockerPopup: true })
          return
        }
      }
      this.setState({
        showWaitingForLockModal: false,
        currentlyOpeningALocker: false,
        selectedOrderId: null,
        lockerId: null,
      })
      return
    }

    if (openingDoorSuccess && lastOpenDoorSuccessLockerNum !== lockerNum) {
      console.log("OPEN DOOR SUCCESS!")
      if (selectedOrderId && lockerId) {
        console.log(
          "componentWillReceiveProps> onDriverReturnesCleanOrder: order:" +
            selectedOrderId +
            " locker:" +
            lockerId
        )

        await this.props.onDriverReturnesCleanOrder(
          selectedOrderId,
          lockerId,
          stationId
        )

        //console.log("onDriverReturnesCleanOrder", selectedOrderId, lockerId)
      } else {
        console.log(
          "selectedOrderId && lockerId ARE FALSE!",
          selectedOrderId,
          lockerId
        )
        //this.setState({ showErrorPutToLocker: true })
        return
      }
      this.setState({
        showWaitingForLockModal: true,
        enableUnresponsiveDoorBtn: false,
        currentlyOpeningALocker: false,
        lastOpenDoorSuccessLockerNum: lockerNum,
      })

      setTimeout(
        () =>
          this.setState({
            enableUnresponsiveDoorBtn: true,
          }),
        10 * 1000
      )

      return
    }

    if (openingErrorOccurred) {
      console.log("OPENING DOOR ERROR OCCURED!")
      if (openAttemptsNum < nextMaxAttempts) {
        this.openAndListenToLocker(lockerNum, 5, stationId)
      } else {
        console.log("OVER ATTEMPTS NUMBER!")
        this.setState({
          showOpeningLockerFailModal: true,
          currentlyOpeningALocker: false,
        })
      }
    }
  }

  onCellClick(cellNum, lockerData = {}) {
    const { selectedOrderId } = this.state
    const { stationId } = this.props

    if (!selectedOrderId) {
      this.setState({ showWarningModal: true })
      return
    }

    const { lockerId, lockerStatus, disabled } = lockerData

    if (lockerStatus !== 3 || disabled) {
      this.setState({ showWarning2Modal: true })
      return
    }

    this.openALockerAndWaitForClose(cellNum, lockerId, stationId)
  }

  openALockerAndWaitForClose(lockerNum, lockerId, stationId) {
    this.openAndListenToLocker(lockerNum, null, stationId)

    const { openingAttempsMap } = this.props

    const openAttemptsNum = openingAttempsMap[lockerNum] || 0

    this.setState({
      lockerNum,
      lockerId,
      currentlyOpeningALocker: true,
      nextMaxAttempts: openAttemptsNum + 4,
    })
  }

  async openAndListenToLocker(lockerNum, attempts, stationId) {
    const { stationPaymentVersion } = this.props
    await this.props.openAndListenToSpecificLockerLock(
      lockerNum,
      attempts,
      stationId,
      undefined, //packageNumber
      2, //it's lockerStatus (2 means 'full', 3 means 'empty')
      stationPaymentVersion
    )
  }

  updateCellsAndList() {
    const { stationId } = this.props

    this.props.onGetReadyOrders(stationId)
    this.props.fetchAllLockersStatuses(stationId) // REFRESHING THE LOCKERS STATUSES..!
  }

  onReadyOrderListItemClick(orderId, orderData) {
    this.setState({
      selectedOrderId: orderId,
      selectedOrderData: orderData,
    })
  }
  onReadyOrderListItemScan(orderId, orderData) {
    const stopScan = this.stopBarcodeScan()
    console.log("stopScan:" + stopScan)
    if (stopScan) {
      return
    }

    this.setState({
      selectedOrderId: orderId,
      selectedOrderData: orderData,
    })
  }
  componentDidUpdate() {
    if (this.refOnSelectOrder.current != null) {
      this.executeScroll()
    }
  }
  executeScroll = () => this.refOnSelectOrder.current.scrollIntoView()

  renderReadyOrdersList() {
    let { readyOrdersList, orderTypesArr, isHideDriverReadyItemButton } =
      this.props
    const { selectedOrderId } = this.state

    if (readyOrdersList.length <= 0) {
      return <div className="no-orders-text">אין הזמנות להעמסה בעמדה זו</div>
    }

    readyOrdersList.forEach((element) => {
      element.orderTypeName = getOrderTypeTextFromOrderType(
        orderTypesArr,
        element.orderType
      )
    })

    let headerItems = [
      "מס' הזמנה",
      "מס' חבילה",
      "נייד",
      "שם לקוח",
      "סוג הזמנה",
      "פעולה",
    ]

    if (isHideDriverReadyItemButton) {
      headerItems = [
        "מס' הזמנה",
        "מס' חבילה",
        "נייד",
        "שם לקוח",
        "סוג הזמנה",
        "",
      ]
    }
    return (
      <ReadyOrdersDetailsList
        HeaderItems={headerItems}
        Items={readyOrdersList}
        onItemClick={(orderId, orderData) =>
          this.onReadyOrderListItemClick(orderId, orderData)
        }
        orangeListItem={selectedOrderId}
        refOnSelect={this.refOnSelectOrder}
        hideSelectReadyItemBtn={isHideDriverReadyItemButton}
      />
    )
  }

  searchOrder(barcode) {
    const { readyOrdersList, role } = this.props

    const matchList = findOrderByBarcodeAndRole(barcode, readyOrdersList, role)

    if (matchList && matchList.length > 0) {
      this.onReadyOrderListItemClick(matchList[0].orderId, matchList[0])
      this.setState({ showNotFoundBarcodeModal: false })
    } else {
      this.setState({
        showNotFoundBarcodeModal: true,
        selectedOrderId: null,
        selectedOrderData: null,
      })
    }
  }

  closeWaitingForLockModal(notifyServerDriverPutCleanOrder) {
    const {
      selectedOrderId,
      lockerId,
      lastOpenDoorSuccessLockerNum: lastLNum,
    } = this.state
    const { stationId } = this.props

    this.setState({
      showWaitingForLockModal: false,
      enableUnresponsiveDoorBtn: false,
      selectedOrderId: null,
      lockerId: null,
      lastOpenDoorSuccessLockerNum: notifyServerDriverPutCleanOrder
        ? lastLNum
        : null,
    })

    if (notifyServerDriverPutCleanOrder) {
      console.log(
        "closeWaitingForLockModal> onDriverReturnesCleanOrder: order:" +
          selectedOrderId +
          " locker:" +
          lockerId
      )

      this.props.onDriverReturnesCleanOrder(
        selectedOrderId,
        lockerId,
        stationId
      )
      console.log("onDriverReturnesCleanOrder", selectedOrderId, lockerId)
      //setTimeout(() => this.updateCellsAndList(), 800)
    } else {
      //Cancel order put_order

      this.props.onDriverReturnesCleanOrderCancel(
        selectedOrderId,
        lockerId,
        stationId
      )
    }
  }

  render() {
    const {
      selectedLocker,
      showWarningModal,
      showWarning2Modal,
      showWarning3Modal,
      showWaitingForLockModal,
      selectedOrderData,
      currentlyOpeningALocker,
      lockerNum,
      showOpeningLockerFailModal,
      enableUnresponsiveDoorBtn,
      showManualPackageSearch,
      showNotFoundBarcodeModal,
    } = this.state

    const { driver_menu_ready_orders_loading, close_text, dirRtl } = this.props
    const enableUnresponsiveClose10sPassed = enableUnresponsiveDoorBtn
      ? () => this.closeWaitingForLockModal(true)
      : null
    const orderNumber = selectedOrderData ? selectedOrderData.orderNumber : ""
    return (
      <div className="barcode-screen-wrapper">
        <FixedHeader
          orderId="שלום נהג שמח!"
          suborderId="פרטי הזמנה להעמסה"
          buttonsFlag="loadCleanOrdersScreen"
        />
        <div className="driver-screen-title">
          {driver_menu_ready_orders_loading}
        </div>
        <div className="dlacs-frame-container">
          <div className="dlacs-inner-wrapper">
            <LockerCells
              onCellClick={this.onCellClick.bind(this)}
              blinkers={{ [selectedLocker]: selectedLocker }}
              extraClass="dlacs-cells"
            />
          </div>
          {this.renderReadyOrdersList()}
          <OrderBarcodeReader
            onOrderFound={(orderId, orderData) =>
              this.onReadyOrderListItemScan(orderId, orderData)
            }
          />
        </div>
        <div className="white-button-wrapper">
          <WhiteButton
            onClick={(e) =>
              this.setState({
                showManualPackageSearch: true,
                selectedOrderId: null,
                selectedOrderData: null,
              })
            }
          >
            חיפוש ברקוד ידני
          </WhiteButton>
        </div>
        <ManualPackageSearchModal
          show={showManualPackageSearch}
          onCloseClicked={() =>
            this.setState({ showManualPackageSearch: false })
          }
          title={"חיפוש חבילה ידני"}
          onClickSearch={(e) => this.searchOrder(e)}
          btnText="חפש חבילה"
          dirRtl
        />
        <WarningInfoModal
          show={showNotFoundBarcodeModal}
          title="חיפוש חבילה ידני"
          text="חבילה לא נמצאה ברשימה"
          onModalCloseClick={() =>
            this.setState({ showNotFoundBarcodeModal: false })
          }
          btnText={close_text}
          dirRtl={dirRtl}
        />

        <LoadingInfoModal
          show={currentlyOpeningALocker}
          title={`פותח את תא מספר ${lockerNum}`}
          text="...אנא המתן"
          dirRtl={dirRtl}
        />

        <WarningInfoModal
          show={showWarningModal}
          title="בחר הזמנה תחילה"
          text="בכדי להעמיס את התא הנבחר, בחר את ההזמנה תחילה"
          onModalCloseClick={() => this.setState({ showWarningModal: false })}
          btnText={close_text}
          dirRtl={dirRtl}
        />

        <WarningInfoModal
          show={showWarning2Modal}
          title="לוקר זה תפוס"
          text='הלוקר הנבחר תפוס כבר ע"י הזמנה אחרת, אנא בחר לוקר אחר להעמסה'
          onModalCloseClick={() => this.setState({ showWarning2Modal: false })}
          btnText={close_text}
          dirRtl={dirRtl}
        />
        <WarningInfoModal
          show={showWarning3Modal}
          title="לוקר זה אינו מתאים לאחסון כביסה"
          text="הלוקר הנבחר אינו מסוג הלוקרים שיכולים להכיל כביסה, אלא דואר או כל מוצר מסוג אחר"
          onModalCloseClick={() => this.setState({ showWarning3Modal: false })}
          btnText={close_text}
          dirRtl={dirRtl}
        />

        <WaitingForLockerLock
          title={`,פותח את תא מספר ${lockerNum}. בסיום הנחת ההזמנה בלוקר`}
          subTitle="אנא לסגור את דלת התא"
          show={showWaitingForLockModal}
          orderData={selectedOrderData}
          onUnresponsiveDoorClick={enableUnresponsiveClose10sPassed}
          onOopsClicked={() => this.closeWaitingForLockModal(false)}
          dirRtl={dirRtl}
        />

        <ErrorInfoModal
          show={showOpeningLockerFailModal}
          title="תקלה בעת ניסיון פתיחת הדלת"
          text="אירעה תקלה בעת ניסיון פתיחת הדלת לאחר 4 פעמים"
          onModalCloseClick={() =>
            this.setState({ showOpeningLockerFailModal: false })
          }
          closeTrnsl={close_text}
          dirRtl={dirRtl}
        />

        <CheckLockerModal
          show={this.state.showCheckLockerPopup}
          onModalCloseClick={() =>
            this.setState({ showCheckLockerPopup: false })
          }
          title="אנא בדוק שוב אם התא מלא"
        />
        <CheckLockerModal
          show={this.state.showErrorPutToLocker}
          onModalCloseClick={() => {
            this.setState({ showErrorPutToLocker: false })
            //this.props.cleanPutToLockerError()
          }}
          title="קליטת החבילה במערכת נכשלה. אנא נסה שוב"
        />

        <CheckLockerModal
          show={this.state.showErrorCancelPutToLocker}
          onModalCloseClick={() => {
            this.setState({ showErrorCancelPutToLocker: false })
            //this.props.cleanCancelPutToLockerError()
          }}
          title={`ביטול טעינת החבילה מספר  נכשל. אנא צור קשר עם שרות לקוחות`}
        />

        <FixedFooter history={this.props.history} />
      </div>
    )
  }
}
const mapStateToProps = ({
  readyOrders,
  lockers,
  bgService,
  lockersCells,
  orderTypes,
  userLogin,
  stationServiceTypes,
  translate,
}) => {
  const { authenticated, role, isHideDriverReadyItemButton } = userLogin
  const { isStationHaveBarcodeReader, stationPaymentVersion } =
    stationServiceTypes
  const { readyOrdersArr } = readyOrders
  const { openedLocker, closedLocker } = lockers
  const { stationId } = bgService
  const { orderTypesArr } = orderTypes
  const { driver_menu_ready_orders_loading, close_text } = translate

  const {
    openedLockersArr,
    errorLockersArr,
    openingAttempsMap,
    recentClosedLockersArr,
    recentOpenAttemptLockerNum,
    failPutToLocker,
    failCancelPutToLocker,
  } = lockersCells

  return {
    stationPaymentVersion,
    authenticated,
    role,
    isHideDriverReadyItemButton,
    readyOrdersList: readyOrdersArr,
    openedLocker,
    closedLocker,
    stationId,
    orderTypesArr,
    isStationHaveBarcodeReader,
    openedLockersArr,
    errorLockersArr,
    openingAttempsMap,
    recentClosedLockersArr,
    recentOpenAttemptLockerNum,
    driver_menu_ready_orders_loading,
    close_text,
    failPutToLocker,
    failCancelPutToLocker,
  }
}
export default connect(mapStateToProps, {
  onGetReadyOrders,
  onDriverReturnesCleanOrder,
  onDriverReturnesCleanOrderCancel,
  setPageAsVisited,
  logScreenEnterance,
  LogoutUser,
  fetchAllLockersStatuses,
  openAndListenToSpecificLockerLock,
  fetchOrderTypes,
  cleanPutToLockerError,
  cleanCancelPutToLockerError,
})(LoadCleanOrdersScreen)
