import React, { Component } from "react"
import { connect } from "react-redux"
import {
  fetchAllStationsData,
  handleRegistrationSubmit,
  logScreenEnterance,
  updateUserPassword,
} from "../../../../../actions"
import { validation } from "../../../../../utils"
import {
  OrangeSelect,
  DisEnbPendBtn,
  InputFiled,
  OrangeButton,
  Progressbar,
} from "../../../../common"
import VirtualKeyboard from "../../../../common/VirtualKeyboard"
import LargeTextModal from "../../../../Modals/LargeTextModal"
import Modal from "react-modal"
import { FixedHeader } from "../../../../reduxified"
import { privacyPolicy } from "./privacyPolicy"
import "./style.css"
import TermsAndPolicyCb from "./TermsAndPolicyCb"
import { termsOfUse } from "./termsOfUse"

class SignUpStep2Screen extends Component {
  constructor(props) {
    super(props)

    this.INIT = {
      pwd: "",
      rpwd: "",
      cmpName: "",

      pwdRef: null,
      rpwdRef: null,
      cmpNameRef: null,

      activeInput: null,
      activeInputName: null,

      isCheckBoxChecked: false,

      pwdErr: false,
      rpwdErr: false,
      isSubmitBtnDisabled: true,
      errorsTimeout: null,

      showLegalInformationModal: false,
      largeModalTitle: "",
      largeModalContent: "",

      runValidationsTimeOut: null,
      isSubmitClicked: false,

      inputPasswordType: "password",
      inputRetypePasswordType: "password",
    }

    this.state = this.INIT
  }

  componentWillMount() {
    if (!this.props.stationId || this.props.stationId.length < 1) {
      this.props.history.push("/")
    }
    this.props.fetchAllStationsData()
  }

  componentDidMount() {
    this.props.logScreenEnterance("USER_SIGN_UP_2_SCREEN")
  }

  componentWillUnmount() {
    this.state = this.INIT
  }

  componentWillReceiveProps(newProps) {
    const { authenticated, isPasswordTemporary } = newProps

    if (authenticated && !isPasswordTemporary) {
      this.props.history.push("/SimpleServiceMenuScreen")
    }
  }

  onKeyboardTextChange(text) {
    const { activeInputName, errorsTimeout } = this.state

    if (errorsTimeout) {
      clearTimeout(errorsTimeout)
    }

    this.setState({
      [activeInputName]: text,
      pwdErr: false,
      rpwdErr: false,
      errorsTimeout: null,
    })

    this.runValidationsWithTimoutDebounce()
  }

  onRefReady(refName, ref) {
    if (!this.state[refName]) {
      this.setState({ [refName]: ref })
    }
  }

  onInputFocusChange(refName, isFocused) {
    if (isFocused) {
      this.setState({
        activeInput: this.state[refName],
        activeInputName: refName.replace("Ref", ""),
      })
    } else {
      if (this.state.activeInput === this.state[refName])
        this.setState({ activeInput: null })
    }
  }

  onSubmitClick() {
    const {
      mobile,
      email,
      firstName,
      lastName,
      stationId,
      authenticated,
      userId,
      isPasswordTemporary,
    } = this.props
    this.setState({ isSubmitClicked: true })

    if (authenticated && isPasswordTemporary) {
      console.log("PASSWORD IS TEMP")

      const userdata = {
        userId,
        password: this.state.pwd,
      }

      this.props.updateUserPassword(userdata)
      this.props.history.push("/SimpleServiceMenuScreen")
      return
    }

    const userdata = {
      mobile,
      email,
      firstName,
      lastName,
      password: this.state.pwd,
    }

    this.props.handleRegistrationSubmit(userdata, stationId, true)
  }

  onBackClick() {
    this.props.history.goBack()
  }

  runValidations() {
    const { pwd, rpwd, isCheckBoxChecked } = this.state

    const isPwdInputValid = validation.PwdStrong(pwd)
    const isRPwdInputValid = validation.PwdVerify(pwd, rpwd)

    const isEmptyPwd = pwd.length < 1
    const isEmptyRpwd = rpwd.length < 1

    const isSubmitBtnDisabled =
      !isPwdInputValid || !isRPwdInputValid || !isCheckBoxChecked
    const pwdErr = !isPwdInputValid && !isEmptyPwd
    const rpwdErr = !isRPwdInputValid && !isEmptyRpwd

    if (!isSubmitBtnDisabled) {
      this.setState({
        isSubmitBtnDisabled,
        pwdErr,
        rpwdErr,
        runValidationsTimeOut: null,
      })
    } else {
      const errorsTimeout = setTimeout(
        () =>
          this.setState({
            isSubmitBtnDisabled,
            pwdErr,
            rpwdErr,
            runValidationsTimeOut: null,
          }),
        800
      )

      this.setState({ errorsTimeout })
    }
  }

  onTermsLinkClick() {
    this.setState({
      largeModalTitle: "תנאי שימוש",
      largeModalContent: termsOfUse,
      showLegalInformationModal: true,
    })
  }

  onPrivacyPolicyClick() {
    this.setState({
      largeModalTitle: "מדיניות הפרטיות",
      largeModalContent: privacyPolicy,
      showLegalInformationModal: true,
    })
  }

  onModalFinished() {
    this.setState({
      showLegalInformationModal: false,
    })
  }

  onCheckboxChange(newState) {
    let { isSubmitBtnDisabled } = this.state

    if (!newState) {
      isSubmitBtnDisabled = true
    }

    this.setState({
      isCheckBoxChecked: newState,
      isSubmitBtnDisabled,
    })

    this.runValidationsWithTimoutDebounce()
  }

  onInputFieldChange(inputName, val) {
    this.setState({ [inputName]: val })

    this.runValidationsWithTimoutDebounce()
  }

  runValidationsWithTimoutDebounce() {
    let { runValidationsTimeOut } = this.state

    if (runValidationsTimeOut) {
      clearTimeout(runValidationsTimeOut)
    }

    runValidationsTimeOut = setTimeout(() => this.runValidations(), 500)

    this.setState({ runValidationsTimeOut })
  }

  onViewPasswordClick() {
    const { inputPasswordType } = this.state
    if (inputPasswordType === "password") {
      this.setState({ inputPasswordType: "text" })
    } else {
      this.setState({ inputPasswordType: "password" })
    }
  }

  onViewPasswordRetypeClick() {
    const { inputRetypePasswordType } = this.state
    if (inputRetypePasswordType === "password") {
      this.setState({ inputRetypePasswordType: "text" })
    } else {
      this.setState({ inputRetypePasswordType: "password" })
    }
  }

  render() {
    const {
      pwd,
      rpwd,
      activeInputName,
      isCheckBoxChecked,
      pwdErr,
      rpwdErr,
      isSubmitBtnDisabled,
      showLegalInformationModal,
      largeModalContent,
      largeModalTitle,
      isSubmitClicked,
      inputPasswordType,
      inputRetypePasswordType,
    } = this.state

    const {
      dirRtl,
      welcome_title_text,
      back_btn_text,
      ok_text,
      star_pwd_input_text_hint,
      retyped_pwd_input_text_hint,
      termsCbTranslation,
      validationtext,
    } = this.props

    return (
      <div className="sus2s-wrapper">
        <FixedHeader
          title={welcome_title_text}
          buttonsFlag="passwordUpdateScreen"
        />

        <div className="sus2s-body" dir={dirRtl ? "rtl" : "ltr"}>
          <Progressbar max={2} currVal={2} />

          <div className="sus2s-frame-wrapper">
            <div className="sus2s-frame-inner-wrapper">
              <InputFiled
                value={pwd}
                type={inputPasswordType}
                onViewPasswordClick={(e) => this.onViewPasswordClick()}
                showEye
                placeholder={star_pwd_input_text_hint}
                onRefReady={(ref) => this.onRefReady("pwdRef", ref)}
                onChange={(e) => this.onInputFieldChange("pwd", e.target.value)}
                onFocusChanged={(isFocused) =>
                  this.onInputFocusChange("pwdRef", isFocused)
                }
                displayErr={pwdErr}
                mandatory
                dirRtl={dirRtl}
                icon="mini_lock"
                validationtext={validationtext.password}
                disabled={isSubmitClicked}
                step1
              />
              <InputFiled
                value={rpwd}
                type={inputRetypePasswordType}
                onViewPasswordClick={(e) => this.onViewPasswordRetypeClick()}
                showEye
                placeholder={retyped_pwd_input_text_hint}
                onRefReady={(ref) => this.onRefReady("rpwdRef", ref)}
                onChange={(e) =>
                  this.onInputFieldChange("rpwd", e.target.value)
                }
                onFocusChanged={(isFocused) =>
                  this.onInputFocusChange("rpwdRef", isFocused)
                }
                displayErr={rpwdErr}
                mandatory
                dirRtl={dirRtl}
                icon="mini_lock"
                validationtext={validationtext.passwordVerification}
                disabled={isSubmitClicked}
                step1
              />
            </div>

            {/* {this.renderStationBranchNameAndCompanyName()} */}

            <div className="sus2s-frame-inner-wrapper">
              <TermsAndPolicyCb
                isCheckBoxChecked={isCheckBoxChecked}
                onCheckboxChange={(isCheck) => this.onCheckboxChange(isCheck)}
                onTermsClick={() => this.onTermsLinkClick()}
                onPrivacyClick={() => this.onPrivacyPolicyClick()}
                translation={termsCbTranslation}
              />
            </div>
            <div className="sus2s-details-btns">
              <DisEnbPendBtn
                extraClass="sus2s-details-single-btn"
                onClick={(e) => this.onSubmitClick()}
                isPending={isSubmitClicked}
                isDisabled={isSubmitBtnDisabled}
              >
                {ok_text}
              </DisEnbPendBtn>
              <OrangeButton
                extraClass="sus2s-details-single-btn"
                onClick={(e) => this.onBackClick()}
              >
                {back_btn_text}
              </OrangeButton>
            </div>
          </div>
          {/* </div> */}
        </div>
        <VirtualKeyboard
          currentValue={this.state[activeInputName]}
          onKeyboardInputChange={(txt) => this.onKeyboardTextChange(txt)}
          show={true}
          lang="en"
          closeOnEnter
          blockLangSwitch
        />
        <Modal
          isOpen={showLegalInformationModal}
          onRequestClose={() => this.onModalFinished()}
          className="react-modal-content big-react-modal"
          overlayClassName="modal-overlay"
        >
          <div className="lim-details-container">
            <p className="lim-label-extra-class">{largeModalTitle}</p>
            <iframe
              src="https://done.co.il/policy/"
              className="iframe_policy"
            ></iframe>
            <OrangeButton
              extraClass="lim-close-btn"
              onClick={() => this.onModalFinished()}
            >
              חזרה
            </OrangeButton>
          </div>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = ({ registration, userLogin, bgService, translate }) => {
  const { mobile, email, firstName, lastName, allStationsData } = registration
  const {
    authenticated,
    userId,
    role,
    firstName: fName,
    lastName: lName,
    isPasswordTemporary,
    email: userEmail,
    mobile: userMobile,
  } = userLogin
  const { stationId } = bgService

  const {
    dirRtl,
    welcome_title_text,
    back_btn_text,
    ok_text,
    star_pwd_input_text_hint,
    retyped_pwd_input_text_hint,
    star_cmpName_input_text_hint,
    im_approving,
    terms_of_use,
    and_the_privacy_policy,
    validation_empty_text,
    validation_password_text,
    validation_password_verification_text,
    validation_name_text,
  } = translate

  let validationtext = {
    empty: validation_empty_text,
    password: validation_password_text,
    passwordVerification: validation_password_verification_text,
    name: validation_name_text,
  }
  const termsCbTranslation = {
    im_approving,
    terms_of_use,
    and_the_privacy_policy,
  }

  const currentStationIndex = allStationsData.indexOf(
    allStationsData.find((station) => station.stationId === stationId)
  )

  return {
    mobile,
    email,
    firstName,
    lastName,
    authenticated,
    stationId,
    userId,
    role,
    fName,
    lName,
    isPasswordTemporary,
    userEmail,
    userMobile,
    allStationsData,
    currentStationIndex,

    dirRtl,
    welcome_title_text,
    back_btn_text,
    ok_text,
    star_pwd_input_text_hint,
    retyped_pwd_input_text_hint,
    star_cmpName_input_text_hint,
    termsCbTranslation,
    validationtext,
  }
}

export default connect(mapStateToProps, {
  handleRegistrationSubmit,
  updateUserPassword,
  fetchAllStationsData,
  logScreenEnterance,
})(SignUpStep2Screen)
