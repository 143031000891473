import React from "react"
import Modal from "react-modal"
import { OrangeButton } from "../../common"
import alertIcon from "../../../assets/svg/alert-icon-info.svg"
import "./style.css"

const CheckLockerModal = ({
  show,
  title,
  onModalCloseClick,
  dirRtl,
  className,
}) => {
  if (!show) {
    return <noscript />
  }

  return (
    <Modal
      isOpen={show}
      onRequestClose={onModalCloseClick}
      className={"react-modal-content " + className}
      overlayClassName="modal-overlay"
    >
      <div className="wheelchair-wrapper">
        <img width={100} src={alertIcon} alt="alertIcon" />
      </div>
      <div
        className="errinm-label-extra-class check-locker-title"
        dir={dirRtl ? "rtl" : "ltr"}
      >
        {title}
      </div>

      <OrangeButton onClick={(e) => onModalCloseClick()}>סגור</OrangeButton>
    </Modal>
  )
}

export default CheckLockerModal
