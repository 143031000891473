import React, { Component } from "react"

import { connect } from "react-redux"

import { OrangeButton } from "../../../common"
import { FixedHeader } from "../../../reduxified"
import {
  logScreenEnterance,
  LogoutUser,
  fetchAllLockersStatuses,
} from "../../../../actions"

import "./style.css"
import ErrorInfoModal from "../../../Modals/ErrorInfoModal"

class DriverMenuScreen extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showError: false,
    }
  }

  componentDidMount() {
    const { authenticated, role, stationId, isNeedAccessibility } = this.props
    this.props.fetchAllLockersStatuses(stationId, isNeedAccessibility)
    if (authenticated && role === "Customer") {
      this.props.LogoutUser()
      this.props.history.push("")
      this.setState({ showError: true })
    }

    if (!authenticated) {
      this.props.LogoutUser()
      this.props.history.push("")
    }

    this.props.logScreenEnterance("DRIVER_MENU_SCREEN")
  }

  componentDidUpdate() {
    const {
      authenticated,
      isDeliveryRole,
      isAnyOrderToReturn,
      isCollectOrdersScreenVisited,
      isLoadCleanOrdersScreenVisited,
    } = this.props

    if (!authenticated) {
      this.props.LogoutUser()
      this.props.history.push("")
    }

    if (isDeliveryRole && isAnyOrderToReturn && !isCollectOrdersScreenVisited) {
      this.props.history.push("CollectOrdersScreen")
    }
  }

  onDriverLoadingACellClick() {
    this.props.history.push("LoadCleanOrdersScreen")
  }

  onDriverCollectFromACellClick() {
    this.props.history.push("CollectOrdersScreen")
  }

  onDriverDeliberatelyOpenCellsClick() {
    this.props.history.push("UnlockLockersScreen")
  }

  onGoBackClick() {
    this.props.history.goBack()
  }

  render() {
    const {
      dirRtl,
      user_menu_what_sub_title_text,
      driver_menu_ready_orders_loading,
      driver_menu_orders_collecting,
      driver_menu_lockers_cells_opening,
      back_btn_text,
      hello_title_text,
      firstName,
      isShowUnlockScreen,
      login_just_for_drivers,
      close_text,
    } = this.props
    const { showError } = this.state

    return (
      <div className="ls-wrapper">
        <FixedHeader buttonsFlag="driverMenuScreen" />
        <div className="dms-title" dir={dirRtl ? "rtl" : "ltr"}>
          {`${hello_title_text}, ${firstName}`}
        </div>
        <div className="dms-subtitle" dir={dirRtl ? "rtl" : "ltr"}>
          {user_menu_what_sub_title_text}
        </div>
        <div className="dms-wrapper">
          <OrangeButton
            extraClass="user-menu-btn"
            onClick={(e) => this.onDriverLoadingACellClick()}
          >
            {driver_menu_ready_orders_loading}
          </OrangeButton>
          <OrangeButton
            extraClass="user-menu-btn"
            onClick={(e) => this.onDriverCollectFromACellClick()}
          >
            {driver_menu_orders_collecting}
          </OrangeButton>
          <OrangeButton
            extraClass="user-menu-btn"
            onClick={(e) => this.onDriverDeliberatelyOpenCellsClick()}
            show={isShowUnlockScreen}
          >
            {driver_menu_lockers_cells_opening}
          </OrangeButton>
          <OrangeButton
            extraClass="user-menu-btn"
            onClick={(e) => this.onGoBackClick()}
          >
            {back_btn_text}
          </OrangeButton>
          <ErrorInfoModal
            dirRtl={dirRtl}
            show={showError}
            text={login_just_for_drivers}
            onModalCloseClick={() => this.setState({ showError: false })}
            closeTrnsl={close_text}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({
  translate,
  userLogin,
  lockersCells,
  visitingScreens,
  bgService,
}) => {
  const {
    authenticated,
    isDeliveryRole,
    firstName,
    isShowUnlockScreen,
    role,
    isNeedAccessibility,
  } = userLogin
  const { isAnyOrderToReturn } = lockersCells
  const { isCollectOrdersScreenVisited, isLoadCleanOrdersScreenVisited } =
    visitingScreens
  const { stationId } = bgService
  const {
    dirRtl,
    user_menu_what_sub_title_text,
    driver_menu_ready_orders_loading,
    driver_menu_orders_collecting,
    driver_menu_lockers_cells_opening,
    back_btn_text,
    hello_title_text,
    login_just_for_drivers,
    close_text,
  } = translate

  return {
    authenticated,
    role,
    isDeliveryRole,
    isShowUnlockScreen,
    isAnyOrderToReturn,
    isCollectOrdersScreenVisited,
    isLoadCleanOrdersScreenVisited,
    dirRtl,
    hello_title_text,
    user_menu_what_sub_title_text,
    driver_menu_ready_orders_loading,
    driver_menu_orders_collecting,
    driver_menu_lockers_cells_opening,
    back_btn_text,
    login_just_for_drivers,
    firstName,
    close_text,
    stationId,
    isNeedAccessibility,
  }
}

export default connect(mapStateToProps, {
  logScreenEnterance,
  LogoutUser,
  fetchAllLockersStatuses,
})(DriverMenuScreen)
