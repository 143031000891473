import React, { Component } from "react"
import { connect } from "react-redux"
import {
  LogoutUser,
  logScreenEnterance,
  openAndListenToSpecificLockerLock,
  resetLockersErrorsAttemptsOpened,
} from "../../../../actions"
import CheckLockerModal from "../../../Modals/CheckLockerModal"
import { FixedFooter, FixedHeader, LockerCells } from "../../../reduxified"
import "./style.css"

class UnlockLockersScreen extends Component {
  constructor(props) {
    super(props)
    // this.isWaiting = undefined
    this.state = {
      isCellWaiting: false,
      showConfirmPopup: false,
      showCheckLockerPopup: false,
      clickedCellNum: null,
      isCellWithOrder: false,
      shouldBeEmpty: 3,
      shouldBeFull: 2,
    }
  }

  componentDidMount() {
    const { authenticated } = this.props
    if (!authenticated) {
      this.props.LogoutUser()
      this.props.history.push("")
    }
    this.props.logScreenEnterance("DRIVER_UNLOCK_LOCKERS_SCREEN")
  }

  componentWillUnmount() {
    this.props.resetLockersErrorsAttemptsOpened()
  }

  componentDidUpdate(prevProps) {
    const { closedLocker } = this.props
    const { clickedCellNum, isCellWithOrder } = this.state

    if (this.state.isCellWaiting === true) {
      setTimeout(() => {
        this.setState({ isCellWaiting: false })
      }, 2000)
    }

    if (prevProps.closedLocker !== closedLocker) {
      if (closedLocker.lockerNum === clickedCellNum) {
        if (
          (closedLocker.isOccupied === 1 && !isCellWithOrder) ||
          (closedLocker.isOccupied === 0 && isCellWithOrder)
        ) {
          this.setState({ showCheckLockerPopup: true })
        }
      }
    }
  }

  onCancelClick() {
    this.props.history.goBack()
  }

  async onCellClick(cellNum, lockerData) {
    if (lockerData === undefined) {
      return
    }
    if (this.state.isCellWaiting) {
      return
    }
    this.setState({ isCellWaiting: true })

    const { order, disabled } = lockerData

    if (disabled) {
      return
    }

    this.setState({
      clickedCellNum: cellNum,
      isCellWithOrder: order != null ? true : false,
    })
    const { role } = this.props

    if (
      order != null &&
      (order.orderStatus === 0 || order.orderStatus === 13) &&
      role !== "Manager" &&
      role !== "DoneDriver" &&
      role !== "Driver"
    ) {
      console.log(
        role +
          "driver is not supposed to pickup an order from unlock lockers screen."
      )
      return
    }
    if (role === "HFD") {
      console.log(
        role +
          " driver is not supposed to pickup an order from unlock lockers screen."
      )
      return
    }
    if (
      order != null &&
      order.orderStatus === 4 &&
      (role === "DHL" || role === "ZigZag")
    ) {
      console.log(
        role +
          " driver is not supposed to pickup an order from unlock lockers screen."
      )
      return
    }

    const { stationId } = this.props
    const { shouldBeEmpty, shouldBeFull } = this.state
    await this.props.openAndListenToSpecificLockerLock(
      cellNum,
      undefined,
      stationId,
      order?.packageNumber,
      order ? shouldBeFull : shouldBeEmpty //lockerStatus
    )
  }

  render() {
    const {
      blinkers,
      errLockers,
      driver_menu_lockers_cells_opening,
      driver_lockers_cells_opening_subtitle,
      role,
    } = this.props

    return (
      <>
        <div className="ls-wrapper">
          <FixedHeader buttonsFlag="unlockLockersScreen" />
          <div className="unlock-screen-title">
            {driver_menu_lockers_cells_opening}
          </div>
          <div className="unlock-screen-subtitle">
            {driver_lockers_cells_opening_subtitle}
          </div>

          <div className="ddocs-inner-wrapper">
            <LockerCells
              onCellClick={this.onCellClick.bind(this)}
              blinkers={blinkers}
              errLockers={errLockers}
            />
          </div>
          <CheckLockerModal
            show={this.state.showCheckLockerPopup}
            onModalCloseClick={() =>
              this.setState({ showCheckLockerPopup: false })
            }
            title={
              this.state.isCellWithOrder
                ? "אנא בדוק שוב אם התא מלא"
                : "אנא בדוק שוב אם התא ריק"
            }
          />

          <FixedFooter history={this.props.history} />
        </div>
      </>
    )
  }
}

const mapStateToProps = ({
  userLogin,
  lockersCells,
  translate,
  bgService,
  lockers,
}) => {
  const { authenticated, role } = userLogin
  const { openedLockersArr, errorLockersArr, lockersData } = lockersCells
  const {
    driver_menu_lockers_cells_opening,
    driver_lockers_cells_opening_subtitle,
  } = translate
  const { stationId } = bgService
  const { closedLocker } = lockers

  const blinkers = {}
  openedLockersArr.map((lockNum) => (blinkers[lockNum] = lockNum))
  const errLockers = {}
  errorLockersArr.map((lockNum) => (errLockers[lockNum] = lockNum))

  return {
    authenticated,
    role,
    lockersData,
    blinkers,
    errLockers,
    driver_menu_lockers_cells_opening,
    driver_lockers_cells_opening_subtitle,
    stationId,
    closedLocker,
  }
}

export default connect(mapStateToProps, {
  openAndListenToSpecificLockerLock,
  logScreenEnterance,
  resetLockersErrorsAttemptsOpened,
  LogoutUser,
})(UnlockLockersScreen)
