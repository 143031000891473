import React, { Component } from "react"
import { connect } from "react-redux"
import { isProdEnv } from "../../../../utils"
import BarcodeReader from "react-barcode-reader"
import {
  onUserSignInClick,
  readStationIdFromBgService,
  readBgServiceConfiguration,
  resetAllReducers,
  changeDisplayedLanguage,
  fetchAllLockersStatuses,
  fetchAllLockersLayout,
  getAllOpenedLockers,
  reportSuspiciousBrokenLockers,
  logScreenEnterance,
  fetchStationServiceTypes,
  LogoutUser,
  onUserSignInWithDoneId,
} from "../../../../actions"
import { AppVersion } from "../../../../utils/Env"
import { FixedHeader, FixedFooter } from "../../../reduxified"
import ServiceMenuButton from "../../../common/ServiceMenuButton"
import ErrorInfoModal from "../../../Modals/ErrorInfoModal"
import OpenedDoorsWarningModal from "../../../Modals/OpenedDoorsWarningModal"
import AppQRCodeButton from "../../../common/AppQRCodeButton"
import DueSituationMessage from "../../../common/DueSituationMessage"
import { validation } from "../../../../utils/Validations"

import "./style.css"

class ServiceMenuScreen extends Component {
  constructor(props) {
    super(props)

    this.state = {
      errorsTimeout: null,
      showErrorModal: false,
      runValidationsTimeOut: null,
      askedAboutOpenedDoors: false,
      openedDoorModalCloseClicked: false,
    }
    this.onGetBarcode = this.onGetBarcode.bind(this)
    this.handleScanError = this.handleScanError.bind(this)
  }

  componentWillMount() {
    if (!this.props.stationId || this.props.stationId.length < 1) {
      this.props.history.push("/")
    }
    this.props.resetAllReducers()
    this.props.LogoutUser()
    this.props.readStationIdFromBgService()
    this.props.readBgServiceConfiguration()
  }

  componentDidMount() {
    const { maxLockerNumber, brokenLockersNumArr, stationId } = this.props

    if (maxLockerNumber) {
      this.setState({ askedAboutOpenedDoors: true })
      this.props.getAllOpenedLockers(maxLockerNumber, brokenLockersNumArr)
    }
    if (stationId != null) {
      this.props.fetchStationServiceTypes(stationId)
    }
    this.props.logScreenEnterance("SERVICE_MENU_SCREEN")
  }

  componentWillReceiveProps(newProps) {
    const {
      errTitle,
      errText,
      stationId,
      maxLockerNumber,
      brokenLockersNumArr,
      isDeliveryActive,
      isLaundryActive,
      isLockerRentActive,
      isLockerToLockerDeliveryActive,
      isReturnPackageActive,
      isReturnExelotActive,
      authenticated,
      isRefillDetails,
    } = newProps

    if (authenticated) {
      const { history } = this.props

      if (isRefillDetails) {
        history.push("CompleteUserDetails")
      }
      if (!isRefillDetails) {
        history.push("SimpleServiceMenuScreen")
      }
    }
    //! TODOD- RESET THE ERR TITLE AND TEXT WHEN STARTING NEW REQ!
    const {
      errTitle: oldErrTitle,
      errText: oldErrText,
      maxLockerNumber: oldMaxLockerNum,
    } = this.props

    if (oldErrTitle !== errTitle || oldErrText !== errText) {
      setTimeout(
        () =>
          this.setState({
            showErrorModal: true,
          }),
        250
      )
    }

    if (stationId === -1) {
      this.props.history.push("MaintenanceScreen")
      return
    }

    if (stationId && !this.props.stationId) {
      // WOULD RUN ONLY THE FIRST TIME.. :)
      this.props.fetchStationServiceTypes(stationId)
      this.props.fetchAllLockersStatuses(stationId)
      this.props.fetchAllLockersLayout(stationId)
    }

    if (
      stationId &&
      this.props.stationId &&
      !this.isStationHaveAnyType(
        isDeliveryActive,
        isLaundryActive,
        isLockerRentActive,
        isLockerToLockerDeliveryActive,
        isReturnPackageActive,
        isReturnExelotActive
      )
    ) {
      this.props.history.push("MaintenanceScreen")
      return
    }

    const { askedAboutOpenedDoors } = this.state

    if (!askedAboutOpenedDoors && maxLockerNumber && !oldMaxLockerNum) {
      this.props.getAllOpenedLockers(maxLockerNumber, brokenLockersNumArr)
    }
  }

  isStationHaveAnyType(
    isDeliveryActive,
    isLaundryActive,
    isLockerRentActive,
    isLockerToLockerDeliveryActive,
    isReturnPackageActive,
    isReturnExelotActive
  ) {
    const isAnyType =
      isDeliveryActive ||
      isLaundryActive ||
      isLockerRentActive ||
      isLockerToLockerDeliveryActive ||
      isReturnPackageActive ||
      isReturnExelotActive
    return isAnyType
  }
  onGetBarcode(barcode) {
    if (this.props.isLoadingRequest) {
      return
    }
    const isBarcodeValid = validation.BarcodeGuid(barcode)

    if (!isBarcodeValid) {
      this.handleScanError(barcode)
      return
    }

    this.setState({ showScanError: false, showErrorModal: false })
    this.props.onUserSignInWithDoneId(barcode)
  }

  handleScanError() {
    console.log("Scan barcode error:")
    this.setState({ showScanError: true })
  }

  onLangChange(lang) {
    const { stationId } = this.props
    this.props.fetchStationServiceTypes(stationId)
    this.props.changeDisplayedLanguage(lang)
  }

  onWarningOpenedDoorsModalOkClicked() {
    const { maxLockerNumber, brokenLockersNumArr } = this.props

    this.props.getAllOpenedLockers(maxLockerNumber, brokenLockersNumArr)
  }

  onOpenedDoorWarningModalCloseClicked() {
    console.log("onOpenedDoorWarningModalCloseClicked")

    const { stationId, lockersWithOpenStatusFromHw } = this.props

    this.setState({ openedDoorModalCloseClicked: true })

    this.props.reportSuspiciousBrokenLockers(
      stationId,
      lockersWithOpenStatusFromHw
    )
  }

  render() {
    const { showErrorModal, openedDoorModalCloseClicked } = this.state

    const {
      errTitle,
      errText,
      lockersWithOpenStatusFromHw,
      dirRtl,
      login_menu_title,
      login_menu_delivery,
      login_menu_landry,
      close_text,
      isDeliveryActive,
      isLaundryActive,
      isLockerRentActive,
      isLockerToLockerDeliveryActive,
      isReturnPackageActive,
      isReturnExelotActive,
      main_title,
      rent_a_locker,
      shipping_return_package,
    } = this.props

    return (
      <div className="ls-wrapper">
        {!isProdEnv() && (
          <div className="testing-version">{`TESTING v${AppVersion}`}</div>
        )}
        <FixedHeader title={login_menu_title} subTitle="" />
        <div className="main-title">{main_title}</div>

        <div className="services-wrapper" dir={dirRtl ? "rtl" : "lrt"}>
          <ServiceMenuButton
            show={isLaundryActive}
            serviceType="laundry"
            text={login_menu_landry}
            onClick={() =>
              this.props.history.push("UserLoginWithDoneLoginScreen", "laundry")
            }
          />
          <ServiceMenuButton
            show={isLockerRentActive}
            serviceType="locker-rent"
            text={rent_a_locker}
            onClick={() => this.props.history.push("AboutRental", "rent")}
          />
          <ServiceMenuButton
            show={
              isLockerToLockerDeliveryActive ||
              isReturnPackageActive ||
              isReturnExelotActive
            }
            serviceType="locker-delivery"
            text={shipping_return_package}
            onClick={() =>
              this.props.history.push(
                "ServiceDeliveryMenuScreen",
                "sendDelivery"
              )
            }
          />
          <ServiceMenuButton
            show={isDeliveryActive}
            serviceType="delivery"
            text={login_menu_delivery}
            onClick={() => this.props.history.push("SmsLoginScreen")}
          />
        </div>
        <DueSituationMessage />
        <AppQRCodeButton
          show={false}
          onClick={() => this.props.history.push("AppQRScreen")}
        />
        <ErrorInfoModal
          dirRtl={dirRtl}
          show={showErrorModal && errText && errText}
          title={errTitle}
          text={errText}
          onModalCloseClick={() => this.setState({ showErrorModal: false })}
          closeTrnsl={close_text}
        />

        <OpenedDoorsWarningModal
          show={
            lockersWithOpenStatusFromHw.length > 0 &&
            !openedDoorModalCloseClicked
          }
          openedDoorsArr={lockersWithOpenStatusFromHw}
          onModalOkClick={() => this.onWarningOpenedDoorsModalOkClicked()}
          onModalCloseClick={() => this.onOpenedDoorWarningModalCloseClicked()}
        />
        <BarcodeReader
          minLength={5}
          onError={(e) => this.handleScanError(e)}
          onScan={(e) => this.onGetBarcode(e)}
        />

        <FixedFooter
          hideBack
          showDriverLogin
          onLangChange={(lang) => this.onLangChange(lang)}
          homePage
        />
      </div>
    )
  }
}

const mapStateToProps = ({
  userLogin,
  bgService,
  lockersCells,
  translate,
  stationServiceTypes,
}) => {
  const { stationId } = bgService
  const { maxLockerNumber, lockersWithOpenStatusFromHw, brokenLockersNumArr } =
    lockersCells
  const {
    authenticated,
    errTitle,
    errText,
    isLoadingRequest,
    isPasswordTemporary,
    isRefillDetails,
    userId,
  } = userLogin
  const {
    dirRtl,
    langName,
    welcome_title_text,
    login_menu_title,
    login_menu_delivery,
    login_menu_landry,
    login_menu_driver,
    close_text,
    system_updgrade_err_msg,
    main_title,
    rent_a_locker,
    shipping_return_package,
  } = translate
  const {
    isDeliveryActive,
    isLaundryActive,
    isLockerRentActive,
    isLockerToLockerDeliveryActive,
    isReturnPackageActive,
    isReturnExelotActive,
  } = stationServiceTypes

  return {
    authenticated,
    errTitle: translate[errTitle],
    errText: translate[errText],
    isLoadingRequest,
    isPasswordTemporary,
    userId,
    stationId,
    maxLockerNumber,
    lockersWithOpenStatusFromHw,
    brokenLockersNumArr,
    main_title,
    dirRtl,
    langName,
    welcome_title_text,
    login_menu_title,
    login_menu_delivery,
    login_menu_landry,
    login_menu_driver,
    close_text,
    system_updgrade_err_msg,
    isDeliveryActive,
    isLaundryActive,
    isLockerRentActive,
    isLockerToLockerDeliveryActive,
    isReturnPackageActive,
    isReturnExelotActive,
    rent_a_locker,
    shipping_return_package,
    isRefillDetails,
  }
}

export default connect(mapStateToProps, {
  onUserSignInClick,
  readStationIdFromBgService,
  readBgServiceConfiguration,
  resetAllReducers,
  changeDisplayedLanguage,
  fetchAllLockersStatuses,
  fetchAllLockersLayout,
  getAllOpenedLockers,
  reportSuspiciousBrokenLockers,
  logScreenEnterance,
  fetchStationServiceTypes,
  LogoutUser,
  onUserSignInWithDoneId,
})(ServiceMenuScreen)
