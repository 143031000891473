import oneProjectLogo from "../assets/images/oneproject_small_logo.png"

let oneProject = {}

oneProject.orderType = 21
oneProject.accountType = 16

oneProject.renderLogo = () => {
  return (
    <img
      className="logo-service-icon oneProject"
      src={oneProjectLogo}
      alt={"logo oneProject"}
    />
  )
}

oneProject.formatWaybill = (value) => {
  let v = value.replace(/\s+/g, "").replace(/[^0-9opOP]/gi, "")

  const matches = v.match(/\[^oO]{1}[^pP]{1}[^0-9]{5,6}$/g)
  let match = (matches && matches[0]) || ""

  let parts = []
  let i = 0
  let len
  for (i = 0, len = match.length; i < len; i += i === 0 || i === 3 ? 2 : 4) {
    parts.push(match.substring(i, i + (i === 0 || i === 3 ? 2 : 4)))
  }

  if (parts.length) {
    return parts.join(" ")
  } else {
    return value
  }
}

oneProject.barcodeMinLength = 12
oneProject.validatePackageBarcode = (barcode) => {
  if (!barcode || barcode.length < 12 || barcode.length > 12) {
    return false
  }
  const barcodeRegex = /^([0-9a-zA-Z]{12}$)/
  const res = barcodeRegex.test(barcode)
  return res
}

export const oneProjectProvider = oneProject
