import axios from "axios"
import api from "../api"
import {
  SET_LOADING_TO_FETCH_ORDER_BY_DELIVERY_CODE,
  FETCH_ORDER_BY_DELIVERY_CODE_SUCCESS,
  FETCH_ORDER_BY_DELIVERY_CODE_FAIL,
  RESET_DELIVERY_REDUCER,
  //RESET_ALL_REDUCERS
} from "./types"
import { axiosPostRetry } from "../utils"
import { reportFBEvent } from "./FBEventsActions"

export const getOrderByDeliveryCode = (stationId, deliveryCode) => {
  return (dispatch) => {
    dispatch({
      type: SET_LOADING_TO_FETCH_ORDER_BY_DELIVERY_CODE,
    })
    axiosPostRetry(
      api.getOrderByDeliveryCode,
      api.getOrderByDeliveryCodeBody(stationId, deliveryCode)
    )
      .then((res) => {
        if (res && res.data && res.data.user) {
          const { token } = res.data.user
          axios.defaults.headers.common["Authorization"] = `Bearer ${token}`
        }
        axios.post("http://localhost:3001/logToStationLogger", {
          csvLine: `RESPONSE getOrderByDeliveryCode, ${res}`, // clear after testing!!!
        })
        dispatch({
          type: FETCH_ORDER_BY_DELIVERY_CODE_SUCCESS,
          payload: api.getOrderByDeliveryCodeResponse(res.data),
        })
        dispatch(
          reportFBEvent(
            "getOrderByDeliveryCode",
            "SUCCESS: deliveryCode:" +
              deliveryCode +
              ", stationId:" +
              stationId +
              "."
          )
        )
        //  TODO
        //if (ENABLE_BG_SYNC) {
        //    sendUserValidDataToLocalBgService(userId, mobile, firstName, lastName, email, pwd, role);
        //}
      })
      .catch((err) => {
        //console.log(err);
        /* TODO
                if (err.message === 'Network Error') {
                    console.log('ASK BG SERVICE HERE!!!');

                    if (ENABLE_BG_SYNC) {
                        authenticateUserWithTheLocalBgService(dispatch, mobile, pwd);
                    } else {
                        dispatch({
                            type: ON_USER_SIGN_IN_FAIL,
                            payload: {
                                // errTitle: err.response.data.message,
                                // errText: err.response.status
                                errTitle: 'err_title_text',     //'שגיאה',
                                errText: 'network_error_text'   //'תקלת תקשורת זמנית'
                            }
                        });
                    }
                    return;
                }
                */

        dispatch({
          type: FETCH_ORDER_BY_DELIVERY_CODE_FAIL,
          payload: api.getOrderByDeliveryCodeError(err),
        })
        axios.defaults.headers.common["Authorization"] = ""
        dispatch(
          reportFBEvent(
            "getOrderByDeliveryCode",
            "ERROR: deliveryCode:" +
              deliveryCode +
              ", stationId:" +
              { stationId } +
              ", error:" +
              err
          )
        )
      })
    dispatch(
      reportFBEvent(
        "getOrderByDeliveryCode",
        "START: deliveryCode:" +
          deliveryCode +
          ", stationId:" +
          { stationId } +
          "."
      )
    )
  }
}

export const resetDeliveryReducer = () => {
  axios.defaults.headers.common["Authorization"] = ""
  return (dispatch) => {
    dispatch({
      type: RESET_DELIVERY_REDUCER,
    })
  }
}

export const getReturnOrderByDeliveryCode = (
  stationId,
  deliveryCode,
  orderType,
  orderStatus
) => {
  return (dispatch) => {
    dispatch({
      type: SET_LOADING_TO_FETCH_ORDER_BY_DELIVERY_CODE,
    })

    axiosPostRetry(
      api.getReturnOrderByDeliveryCode,
      api.getReturnOrderByDeliveryCodeBody(
        stationId,
        deliveryCode,
        orderType,
        orderStatus
      )
    )
      .then((res) => {
        if (res && res.data && res.data.user) {
          const { token } = res.data.user
          axios.defaults.headers.common["Authorization"] = `Bearer ${token}`
        }

        axios.post("http://localhost:3001/logToStationLogger", {
          csvLine: `RESPONSE getReturnOrderByDeliveryCode, ${res}`, // clear after testing!!!
        })
        dispatch({
          type: FETCH_ORDER_BY_DELIVERY_CODE_SUCCESS,
          payload: api.getOrderByDeliveryCodeResponse(res.data),
        })
        dispatch(
          reportFBEvent(
            "getReturnOrderByDeliveryCode",
            "SUCCESS: deliveryCode:" +
              deliveryCode +
              ", stationId:" +
              stationId +
              "."
          )
        )
        //  TODO
        //if (ENABLE_BG_SYNC) {
        //    sendUserValidDataToLocalBgService(userId, mobile, firstName, lastName, email, pwd, role);
        //}
      })
      .catch((err) => {
        dispatch({
          type: FETCH_ORDER_BY_DELIVERY_CODE_FAIL,
          payload: api.getOrderByDeliveryCodeError(err),
        })
        //axios.defaults.headers.common["Authorization"] = ""
        dispatch(
          reportFBEvent(
            "getReturnOrderByDeliveryCode",
            "ERROR: deliveryCode:" +
              deliveryCode +
              ", stationId:" +
              { stationId } +
              ", error:" +
              err
          )
        )
      })
    dispatch(
      reportFBEvent(
        "getOrderByDeliveryCode",
        "START: deliveryCode:" +
          deliveryCode +
          ", stationId:" +
          { stationId } +
          "."
      )
    )
  }
}
