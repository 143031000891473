import axios from "axios"
import api from "../api"
import {
  FETCH_STATION_SERVICE_TYPES_SUCCESS,
  FETCH_STATION_SERVICE_TYPES_FAIL,
} from "./types"
import { axiosGetRetry } from "../utils"

export const fetchStationServiceTypes = (stationId) => {
  // if (stationId === null || stationId < 0)
  //   return (dispatch) => {
  //     dispatch({
  //       type: FETCH_STATION_SERVICE_TYPES_FAIL,
  //       payload: "",
  //     })
  //   }

  return (dispatch) => {
    axiosGetRetry(api.getServiceTypesByStationId(stationId))
      .then((res) => {
        dispatch({
          type: FETCH_STATION_SERVICE_TYPES_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        console.log("error in action", err)
        setTimeout(() => {
          axiosGetRetry(api.getServiceTypesByStationId(stationId))
            .then((res) => {
              dispatch({
                type: FETCH_STATION_SERVICE_TYPES_SUCCESS,
                payload: res.data,
              })
            })
            .catch(() => {
              fetchStationServiceTypes(stationId)
            })
        }, 60000)
      })
  }
}
