import {
  FETCH_STATION_SERVICE_TYPES_SUCCESS,
  FETCH_STATION_SERVICE_TYPES_FAIL,
} from "../actions/types"

const INIT_STATE = {
  isDeliveryActive: false,
  isLaundryActive: false,
  stationPaymentVersion: 0,
  isDeliverySimulationStation: false,
  isStationHaveBarcodeReader: false,
  isLockerRentActive: false,
  isLockerToLockerDeliveryActive: false,
  isReturnPackageActive: false,
  isReturnExelotActive: false,
}

const stationServiceTypesReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_STATION_SERVICE_TYPES_SUCCESS:
      return {
        ...state,
        isDeliveryActive: action.payload.isDeliveryActive,
        isLaundryActive: action.payload.isLaundryActive,
        stationPaymentVersion: action.payload.stationPaymentVersion,
        isDeliverySimulationStation: action.payload.isDeliverySimulationStation,
        isStationHaveBarcodeReader: action.payload.isStationHaveBarcodeReader,
        isLockerRentActive: action.payload.isLockerRentActive,
        isLockerToLockerDeliveryActive:
          action.payload.isLockerToLockerDeliveryActive,
        isReturnPackageActive: action.payload.isReturnPackageActive,
        isReturnExelotActive: action.payload.isReturnExelotActive,
      }
    default:
      return state
  }
}

export default stationServiceTypesReducer
