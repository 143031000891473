import React, { Component } from "react"
import { connect } from "react-redux"
import {
  checkIfMobileAlreadyExists,
  completeUserData,
  logScreenEnterance,
  onStep1UserDataSubmit,
  resetCompleteDetails,
  LogoutUser,
} from "../../../../../actions"
import { reportFBEvent } from "../../../../../actions/FBEventsActions"
import { validation } from "../../../../../utils"
import { DisEnbPendBtn, InputFiled } from "../../../../common"
import VirtalKeyboard from "../../../../common/VirtualKeyboard"
import ErrorInfoModal from "../../../../Modals/ErrorInfoModal"
import { FixedHeader } from "../../../../reduxified"
import FixedFooter from "../../../../reduxified/FixedFooter"
import "./style.scss"

class CompleteUserDetails extends Component {
  constructor(props) {
    super(props)

    this.INIT = {
      firstName: "",
      lastName: "",
      email: "",
      emailVerification: "",

      firstNameRef: null,
      lastNameRef: null,
      emailRef: null,
      emailVerificationRef: null,

      activeInput: null,
      activeInputName: null,

      emailErr: false,
      firstNameErr: false,
      lastNameErr: false,
      isSubmitBtnDisabled: true,
      errorsTimeout: null,

      showErrorModal: false,

      runValidationsTimeOut: null,
      serviceName: "",
    }

    this.state = this.INIT
  }

  componentWillUnmount() {
    this.setState(this.INIT)
  }

  componentDidMount() {
    const { authenticated } = this.props

    if (!authenticated) {
      this.props.reportFBEvent(
        "CompleteUserDetailsScreen",
        "Not authenticated. Redirect to homepage."
      )
      this.props.LogoutUser()
      this.props.history.push("")
    }
    this.setState({ serviceName: this.props.history.location.state })

    const { email, firstName, lastName } = this.props

    this.setState({ email, emailVerification: email, firstName, lastName })

    this.runValidationsWithTimoutDebounce()
    this.props.logScreenEnterance("COMPLETE_USER_SCREEN")
  }

  componentDidUpdate() {
    const {
      authenticated,
      isCompleteDetailsSuccessed,
      isCompleteDetails,
      isPasswordTemporary,
      isUpdatedUserPassword,
      history,
    } = this.props
    const { serviceName } = this.state
    if (!authenticated) {
      this.props.reportFBEvent(
        "CompleteUserDetailsScreen",
        "Not authenticated. Redirect to homepage."
      )
      this.props.LogoutUser()
      history.push("")
    }

    if (isCompleteDetailsSuccessed) {
      if (!isUpdatedUserPassword) {
        if (!serviceName) {
          history.push("SimpleServiceMenuScreen")
        }
        if (serviceName === "laundry") {
          history.push("UserMenuScreen")
        }
        if (serviceName === "rent") {
          history.push("RentLockerMenuScreen")
        }
        if (serviceName === "lockerDelivery") {
          history.push("LockerToLockerDeliveryScreen/info")
        }
      }
    }

    if (!isCompleteDetailsSuccessed && isCompleteDetails) {
      this.setState({ showErrorModal: true })
      this.props.resetCompleteDetails()
    }
  }

  onKeyboardTextChange(text) {
    const { activeInputName, errorsTimeout } = this.state

    if (errorsTimeout) {
      clearTimeout(errorsTimeout)
    }

    this.setState({
      [activeInputName]: text,
      emailErr: false,
      firstNameErr: false,
      lastNameErr: false,
      errorsTimeout: null,
    })

    this.runValidationsWithTimoutDebounce()
  }

  onRefReady(refName, ref) {
    if (!this.state[refName]) {
      this.setState({ [refName]: ref })
    }
  }

  onInputFocusChange(refName, isFocused) {
    if (isFocused) {
      this.setState({
        activeInput: this.state[refName],
        activeInputName: refName.replace("Ref", ""),
      })
    } else {
      if (this.state.activeInput === this.state[refName])
        this.setState({ activeInput: null })
    }
  }

  onContinueClick() {
    const { firstName, lastName, email } = this.state
    const { userId } = this.props
    this.props.completeUserData(userId, firstName, lastName, email)
  }

  runValidations() {
    const { email, firstName, lastName, emailVerification } = this.state

    const firstNameLastNameEmpty = firstName.length < 1 || lastName.length < 1
    const isFirstNameValid = validation.Name(firstName)
    const isLastNameValid = validation.Name(lastName)

    //const isEmailValid = this.isEmailValid(email);
    const isEmailValid = validation.Email(email)
    const isEmptyemail = email.length < 1
    const emailErr = !isEmailValid && !isEmptyemail

    const emailVerificationErr = email !== emailVerification

    const isSubmitBtnDisabled =
      !isEmailValid ||
      firstNameLastNameEmpty ||
      emailVerificationErr ||
      !isFirstNameValid ||
      !isLastNameValid

    if (!isSubmitBtnDisabled) {
      this.setState({
        isSubmitBtnDisabled,
        emailErr,
        emailVerificationErr,
        firstNameErr: !isFirstNameValid,
        lastNameErr: !isLastNameValid,
        runValidationsTimeOut: null,
      })
    } else {
      const errorsTimeout = setTimeout(
        () =>
          this.setState({
            isSubmitBtnDisabled,
            emailErr,
            emailVerificationErr,
            firstNameErr: !isFirstNameValid,
            lastNameErr: !isLastNameValid,
            runValidationsTimeOut: null,
          }),
        800
      )

      this.setState({ errorsTimeout })
    }
  }

  onInputFieldChange(inputName, val) {
    this.setState({ [inputName]: val })
    this.runValidationsWithTimoutDebounce()
  }

  runValidationsWithTimoutDebounce() {
    let { runValidationsTimeOut } = this.state

    if (runValidationsTimeOut) {
      clearTimeout(runValidationsTimeOut)
    }

    runValidationsTimeOut = setTimeout(() => this.runValidations(), 500)

    this.setState({ runValidationsTimeOut })
  }

  onCloseErrorModal() {
    this.setState(this.INIT)
    this.props.resetCompleteDetails()
  }

  render() {
    const {
      firstName,
      lastName,
      email,
      emailVerification,
      activeInputName,
      emailErr,
      emailVerificationErr,
      firstNameErr,
      lastNameErr,
      isSubmitBtnDisabled,
      showErrorModal,
    } = this.state

    const {
      loadingIfMobileTakenInBg,
      dirRtl,
      first_name_input_hint,
      last_name_input_hint,
      email_input_hint,
      email_verification_input_hint,
      next_text,
      signup_cancel_modal_title,
      signup_cancel_modal_body,
      err_title_text,
      ok_text,
      cancel_text,
      login_laundry,
      complete_user_details_title,
      validationtext,
      password_update_error_title,
      password_update_error_text,
      close_text,
      isLoading,
    } = this.props

    const keyboardLang =
      activeInputName === "email" || activeInputName === "emailVerification"
        ? "email"
        : "he"

    return (
      <div className="sus1s-wrapper">
        <FixedHeader
          title={login_laundry}
          subTitle={complete_user_details_title}
        />
        <div className="sus1s-body" dir={dirRtl ? "rtl" : "ltr"}>
          <div className="sus1s-frame-container">
            <div className="top-90">
              <div className="sus1s-frame-wrapper">
                <div className="sus1s-frame-inner-wrapper">
                  <InputFiled
                    value={firstName}
                    onChange={(e) =>
                      this.onInputFieldChange("firstName", e.target.value)
                    }
                    placeholder={first_name_input_hint}
                    onRefReady={(ref) => this.onRefReady("firstNameRef", ref)}
                    onFocusChanged={(isFocused) =>
                      this.onInputFocusChange("firstNameRef", isFocused)
                    }
                    mandatory
                    validationtext={validationtext.name}
                    icon="name"
                    dirRtl={dirRtl}
                    displayErr={firstNameErr}
                    disabled={isLoading}
                    autoFocus
                    step1
                  />
                  <InputFiled
                    value={lastName}
                    onChange={(e) =>
                      this.onInputFieldChange("lastName", e.target.value)
                    }
                    placeholder={last_name_input_hint}
                    onRefReady={(ref) => this.onRefReady("lastNameRef", ref)}
                    onFocusChanged={(isFocused) =>
                      this.onInputFocusChange("lastNameRef", isFocused)
                    }
                    mandatory
                    validationtext={validationtext.name}
                    icon="name"
                    dirRtl={dirRtl}
                    displayErr={lastNameErr}
                    disabled={isLoading}
                    step1
                  />
                </div>

                <div className="sus1s-frame-inner-wrapper">
                  <InputFiled
                    value={email}
                    onChange={(e) =>
                      this.onInputFieldChange("email", e.target.value)
                    }
                    placeholder={email_input_hint}
                    onRefReady={(ref) => this.onRefReady("emailRef", ref)}
                    onFocusChanged={(isFocused) =>
                      this.onInputFocusChange("emailRef", isFocused)
                    }
                    displayErr={emailErr}
                    mandatory
                    validationtext={validationtext.email}
                    icon="mail"
                    dirRtl={dirRtl}
                    disabled={isLoading}
                    step1
                  />
                  <InputFiled
                    value={emailVerification}
                    onChange={(e) =>
                      this.onInputFieldChange(
                        "emailVerification",
                        e.target.value
                      )
                    }
                    placeholder={email_verification_input_hint}
                    onRefReady={(ref) =>
                      this.onRefReady("emailVerificationRef", ref)
                    }
                    onFocusChanged={(isFocused) =>
                      this.onInputFocusChange("emailVerificationRef", isFocused)
                    }
                    displayErr={emailVerificationErr}
                    mandatory
                    validationtext={validationtext.emailVerification}
                    icon="mail"
                    dirRtl={dirRtl}
                    disabled={isLoading}
                    step1
                  />
                </div>

                <DisEnbPendBtn
                  extraClass="btn-margin"
                  onClick={(e) => this.onContinueClick()}
                  isDisabled={isSubmitBtnDisabled}
                  isPending={isLoading}
                >
                  {next_text}
                </DisEnbPendBtn>
              </div>
            </div>
          </div>
        </div>
        <VirtalKeyboard
          currentValue={this.state[activeInputName]}
          onKeyboardInputChange={(txt) => this.onKeyboardTextChange(txt)}
          show={this.state.activeInput}
          lang={keyboardLang}
          closeOnEnter
        />

        <ErrorInfoModal
          dirRtl={dirRtl}
          show={showErrorModal}
          title={password_update_error_title}
          text={password_update_error_text}
          onModalCloseClick={() => this.onCloseErrorModal()}
          closeTrnsl={close_text}
        />

        {/* <FixedFooter
          history={this.props.history}
          hideBack={true}
          showLogout={true}
        /> */}
      </div>
    )
  }
}

const mapStateToProps = ({ registration, translate, userLogin }) => {
  const {
    is1stStepSubmitted, // email, firstName, lastName,
    isCompleteDetailsSuccessed,
    isCompleteDetails,
    isLoading,
  } = registration

  const {
    authenticated,
    isPasswordTemporary,
    isRefillDetails,
    isUpdatedUserPassword,
    userId,
    email,
    firstName,
    lastName,
  } = userLogin

  const {
    dirRtl,
    first_name_input_hint,
    last_name_input_hint,
    email_input_hint,
    email_verification_input_hint,
    next_text,
    signup_cancel_modal_title,
    signup_cancel_modal_body,
    err_title_text,
    ok_text,
    cancel_text,
    close_text,
    create_new_user_exist_mobile_modal_title,
    create_new_user_exist_mobile_modal_text,
    login_laundry,
    complete_user_details_title,
    validation_empty_text,
    validation_mobile_text,
    validation_password_text,
    validation_password_verification_text,
    validation_name_text,
    validation_email_text,
    validation_email_verification_text,
    password_update_error_title,
    password_update_error_text,
  } = translate

  let validationtext = {
    empty: validation_empty_text,
    mobile: validation_mobile_text,
    password: validation_password_text,
    passwordVerification: validation_password_verification_text,
    name: validation_name_text,
    email: validation_email_text,
    emailVerification: validation_email_verification_text,
  }

  return {
    is1stStepSubmitted,
    email,
    firstName,
    lastName,
    authenticated,
    isPasswordTemporary,
    isRefillDetails,
    userId,
    isCompleteDetailsSuccessed,
    isCompleteDetails,
    isUpdatedUserPassword,
    dirRtl,
    first_name_input_hint,
    last_name_input_hint,
    email_input_hint,
    email_verification_input_hint,
    next_text,
    signup_cancel_modal_title,
    signup_cancel_modal_body,
    err_title_text,
    ok_text,
    cancel_text,
    close_text,
    create_new_user_exist_mobile_modal_title,
    create_new_user_exist_mobile_modal_text,
    login_laundry,
    complete_user_details_title,
    validationtext,
    password_update_error_title,
    password_update_error_text,
    close_text,
    isLoading,
  }
}

export default connect(mapStateToProps, {
  onStep1UserDataSubmit,
  checkIfMobileAlreadyExists,
  logScreenEnterance,
  reportFBEvent,
  completeUserData,
  resetCompleteDetails,
  LogoutUser,
})(CompleteUserDetails)
