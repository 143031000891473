import React, { Component } from "react"
import { connect } from "react-redux"
import {
  checkIfMobileAlreadyExists,
  getAllOpenedLockers,
  logScreenEnterance,
  onUserSignInWithDoneId,
  readBgServiceConfiguration,
  readStationIdFromBgService,
  reportSuspiciousBrokenLockers,
  resetAllReducers,
  sendVerifyCode,
} from "../../../../actions"
import InputMobileForOTP from "../../../common/InputMobileForOTP"
import InputOTP from "../../../common/InputOTP"
import { FixedFooter, FixedHeader } from "../../../reduxified"
import "./style.scss"

class LoginWithOTP extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showInputOTP: false,
      mobileNumber: "",
      OTPValue: "",
      showOTPError: false,
    }
  }

  componentDidMount() {
    this.props.resetAllReducers()
  }

  componentDidUpdate() {
    if (this.props.token !== null) {
      this.props.history.push("/DriverMenuScreen")
    }
  }

  async onSubmitClick(mobile) {
    this.props.sendVerifyCode(mobile)
    this.setState({ showInputOTP: true, mobileNumber: mobile })
  }

  render() {
    const { OTPValue, showInputOTP } = this.state

    const { dirRtl } = this.props

    return (
      <div className={"ls-wrapper login-with-otp"}>
        <FixedHeader buttonsFlag="userLoginScreen" />
        <div className="content-wrapper">
          {showInputOTP ? (
            <div className="otp-wrapper">
              <InputOTP
                value={OTPValue}
                onChange={(value) => this.setState({ OTPValue: value })}
                mobile={this.state.mobileNumber}
              />
            </div>
          ) : (
            <div className="input-mobile-otp-wrapper">
              <div className="enter-text" dir={dirRtl}>
                <p className="title-otp-input-mobile">
                  SMS כניסה בעזרת מספר נייד וקוד
                </p>
              </div>
              <InputMobileForOTP
                onClick={(mobile) => this.onSubmitClick(mobile)}
              />
            </div>
          )}
        </div>
        <FixedFooter
          history={this.props.history}
          className="otp-mobile-footer"
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userLogin, bgService, translate }) => {
  const { stationId } = bgService
  const {
    authenticated,
    token,
    errTitle,
    errText,
    isLoadingRequest,
    isRefillDetails,
    userId,
    isError,
    isUserExist,
  } = userLogin

  const {
    dirRtl,
    langName,
    close_text,
    login_laundry,
    enter,
    base_error_title,
    base_error_text,
    done_login_text,
    done_login_error_title,
    done_login_error_text,
    to_password_button_text,
    enter_with_mobile_and_password,
    retry,
    please_wait,
    cellphone_input_text_hint,
    mobileErr,
    validation_mobile_text,
  } = translate

  return {
    authenticated,
    errTitle: translate[errTitle],
    errText: translate[errText],
    isLoadingRequest,
    userId,
    token,
    stationId,
    isError,
    enter,
    dirRtl,
    langName,
    close_text,
    login_laundry,
    base_error_title,
    base_error_text,
    isRefillDetails,
    done_login_text,
    done_login_error_title,
    done_login_error_text,
    to_password_button_text,
    enter_with_mobile_and_password,
    retry,
    please_wait,
    cellphone_input_text_hint,
    mobileErr,
    validation_mobile_text,
    isUserExist,
  }
}

export default connect(mapStateToProps, {
  onUserSignInWithDoneId,
  readStationIdFromBgService,
  readBgServiceConfiguration,
  resetAllReducers,
  getAllOpenedLockers,
  reportSuspiciousBrokenLockers,
  logScreenEnterance,
  checkIfMobileAlreadyExists,
  sendVerifyCode,
})(LoginWithOTP)
