import React, { Component } from "react"
import { connect } from "react-redux"
import {
  changeDisplayedLanguage,
  checkIfMobileAlreadyExists,
  fetchAllLockersLayout,
  fetchAllLockersStatuses,
  logScreenEnterance,
  onUserSignInClick,
  readBgServiceConfiguration,
  readStationIdFromBgService,
  reportSuspiciousBrokenLockers,
  getAllOpenedLockers,
  resetAllReducers,
} from "../../../../actions"
import { validation } from "../../../../utils/Validations"
import { newlineText } from "../../../../utils/TextHelper"
import { DisEnbPendBtn, InputFiled } from "../../../common"
import DoneLoginButton from "../../../common/DoneLoginButton"
import VirtualKeyboard from "../../../common/VirtualKeyboard"
import ErrorInfoModal from "../../../Modals/ErrorInfoModal"
import YesNoInfoModal from "../../../Modals/YesNoInfoModal"
import { FixedFooter, FixedHeader } from "../../../reduxified"

import "./style.scss"

class UserLoginWithDoneLoginScreen extends Component {
  constructor(props) {
    super(props)

    this.INIT_STATE = {
      flow: {
        step1_EnterPhone: true,
        step2_EnterPwd: false,
      },
      mobile: "",
      pwd: "",
      mobileRef: null,
      pwdRef: null,
      activeInput: null,
      activeInputName: null,
      isSubmitBtnDisabled: false,
      mobileErr: false,
      pwdErr: false,
      errorsTimeout: null,
      showErrorModal: false,
      isPasswordResetModalShown: false,
      runValidationsTimeOut: null,
      openedDoorModalCloseClicked: false,
      showPhoneNotFoundModal: false,
      showBaseErrorModal: false,
      inputPasswordType: "password",
      serviceName: "",
    }

    this.state = this.INIT_STATE
    this.mobileInput = React.createRef()
  }

  // componentWillMount() {

  // }

  componentDidMount() {
    this.setState(this.INIT_STATE)

    this.props.resetAllReducers()
    this.props.readStationIdFromBgService()
    this.props.readBgServiceConfiguration()
    const { maxLockerNumber, brokenLockersNumArr } = this.props
    this.props.getAllOpenedLockers(maxLockerNumber, brokenLockersNumArr)

    this.runValidations()
    this.setState({ serviceName: this.props.history.location.state })
    this.props.logScreenEnterance("USER_LOGIN_WITH_DONE_LOGIN")
  }

  componentWillUnmount() {
    let { runValidationsTimeOut, errorsTimeout } = this.state
    if (runValidationsTimeOut) {
      clearTimeout(runValidationsTimeOut)
    }
    if (errorsTimeout) {
      clearTimeout(errorsTimeout)
    }
    this.setState(this.INIT_STATE)
  }

  componentWillReceiveProps(newProps) {
    const {
      authenticated,
      errTitle,
      errText,
      isPasswordTemporary,
      isRefillDetails,
      stationId,
      langName,
      isError,
      history,
    } = newProps
    console.log("tempPassword", isPasswordTemporary)
    this.setState({ showBaseErrorModal: isError })

    if (
      this.state.flow.step1_EnterPhone &&
      newProps.mobileChecked &&
      !newProps.userMobileFree
    ) {
      let flow = this.state.flow
      flow.step1_EnterPhone = false
      flow.step2_EnterPwd = true
      this.setState({ flow })
    }

    if (
      this.state.flow.step1_EnterPhone &&
      newProps.mobileChecked &&
      newProps.userMobileFree
    ) {
      this.setState({ showPhoneNotFoundModal: true })
    }

    if (this.state.flow.step1_EnterPhone) {
      this.setState({
        activeInput: this.state.mobileRef,
        activeInputName: "mobile",
      })
    }
    if (this.state.flow.step2_EnterPwd) {
      this.setState({ activeInput: this.state.pwdRef, activeInputName: "pwd" })
    }

    if (this.state.flow.step2_EnterPwd && authenticated) {
      const { serviceName } = this.state
      const { history } = this.props

      if (isPasswordTemporary) {
        history.push("PasswordUpdateScreen", serviceName)
      }
      if (isRefillDetails && !isPasswordTemporary) {
        history.push("CompleteUserDetails", serviceName)
      }
      if (!isPasswordTemporary && !isRefillDetails) {
        if (serviceName === "laundry") {
          history.push("UserMenuScreen")
        }
        if (serviceName === "rent") {
          history.push("RentLockerMenuScreen")
        }
        if (serviceName === "lockerDelivery") {
          history.push("LockerToLockerDeliveryScreen/info")
        }
      }
    }

    const { errTitle: oldErrTitle, errText: oldErrText } = this.props

    if (oldErrTitle !== errTitle || oldErrText !== errText) {
      setTimeout(
        () =>
          this.setState({
            showErrorModal: true,
          }),
        250
      )
    }

    if (stationId === -1) {
      this.props.history.push("MaintenanceScreen")
      return
    }
  }

  onKeyboardTextChange(text) {
    const { activeInputName, errorsTimeout } = this.state

    if (errorsTimeout) {
      clearTimeout(errorsTimeout)
    }

    this.setState({
      [activeInputName]: text,
      mobileErr: false,
      pwdErr: false,
      errorsTimeout: null,
    })

    this.runValidationsWithTimoutDebounce()
  }

  onRefReady(refName, ref) {
    if (!this.state[refName]) this.setState({ [refName]: ref })
  }

  onInputFocusChange(refName, isFocused) {
    if (isFocused) {
      this.setState({
        activeInput: this.state[refName],
        activeInputName: refName.replace("Ref", ""),
      })
    } else {
      if (this.state.activeInput === this.state[refName])
        this.setState({ activeInput: null })
    }
  }

  runValidations() {
    const { mobile, pwd } = this.state

    const isMobileNumValid = validation.Mobile05(mobile)
    const isPwdValid = validation.Pwd(pwd)

    const isEmptyMobile = mobile.length < 1
    const isEmptyPwd = pwd.length < 1

    const isSubmitBtnDisabled = !isMobileNumValid || !isPwdValid || isEmptyPwd
    const isSubmitMobileBtnDisabled = !isMobileNumValid || isEmptyMobile
    const mobileErr = !isMobileNumValid && !isEmptyMobile
    const pwdErr = !isPwdValid && !isEmptyPwd

    if (!isSubmitBtnDisabled) {
      this.setState({
        isSubmitBtnDisabled,
        mobileErr,
        pwdErr,
        runValidationsTimeOut: null,
      })
    } else {
      const errorsTimeout = setTimeout(
        () =>
          this.setState({
            isSubmitBtnDisabled,
            mobileErr,
            pwdErr,
            runValidationsTimeOut: null,
          }),
        800
      )

      this.setState({ errorsTimeout })
    }

    if (!isSubmitMobileBtnDisabled) {
      this.setState({
        isSubmitMobileBtnDisabled,
        mobileErr,
        runValidationsTimeOut: null,
      })
    } else {
      const errorsTimeout = setTimeout(
        () =>
          this.setState({
            isSubmitMobileBtnDisabled,
            mobileErr,
            runValidationsTimeOut: null,
          }),
        800
      )

      this.setState({ errorsTimeout })
    }
  }

  onViewPasswordClick() {
    const { inputPasswordType } = this.state
    if (inputPasswordType === "password") {
      this.setState({ inputPasswordType: "text" })
    } else {
      this.setState({ inputPasswordType: "password" })
    }
  }

  onSignInClick() {
    const { mobile, pwd } = this.state
    this.props.onUserSignInClick(mobile, pwd)
  }

  checkExistUser() {
    const { mobile } = this.state
    this.props.checkIfMobileAlreadyExists(mobile)
  }

  onInputFieldChange(inputName, val, evt) {
    evt.persist()
    this.setState({ [inputName]: val })
    this.runValidationsWithTimoutDebounce(inputName, val)
  }

  runValidationsWithTimoutDebounce() {
    let { runValidationsTimeOut } = this.state

    if (runValidationsTimeOut) {
      clearTimeout(runValidationsTimeOut)
    }

    runValidationsTimeOut = setTimeout(() => this.runValidations(), 500)

    this.setState({ runValidationsTimeOut })
  }

  filterEnterAndSubmit(e) {
    e.persist()
    if (e.key === "Enter") {
      setTimeout(() => {
        const { isSubmitBtnDisabled } = this.state

        if (!isSubmitBtnDisabled) {
          this.onSignInClick()
        }
      }, 700)
    }
  }

  onLangChange(lang) {
    const { stationId } = this.props
    this.props.changeDisplayedLanguage(lang)
  }

  render() {
    const {
      mobile,
      pwd,
      activeInputName,
      isSubmitBtnDisabled,
      isSubmitMobileBtnDisabled,
      mobileErr,
      pwdErr,
      showPhoneNotFoundModal,
      showErrorModal,
      showBaseErrorModal,
      inputPasswordType,
    } = this.state

    const {
      errTitle,
      errText,
      isLoadingRequest,
      dirRtl,
      cellphone_input_text_hint,
      continue_text,
      close_text,
      login_laundry,
      input_phone_title,
      input_psw_title,
      validationtext,
      phone_number_not_found,
      create_new_user_btn_text,
      isPasswordTemporary,
      input_temp_psw_title,
      base_error_title,
      base_error_text,
      history,
      password_login_button_title,
    } = this.props
    const { serviceName } = this.state

    const phone_number_not_found_text = phone_number_not_found.replace(
      "{mobile}",
      mobile
    )

    const { step1_EnterPhone, step2_EnterPwd } = this.state.flow

    let subTitle = input_phone_title
    let extraClass = " step1_EnterPhone"

    if (!step1_EnterPhone) {
      subTitle = isPasswordTemporary ? input_temp_psw_title : input_psw_title
      extraClass = " step2_EnterPwd"
    }

    return (
      <div className={"ls-wrapper lundry-login" + extraClass}>
        <FixedHeader
          title={login_laundry}
          subTitle={subTitle}
          mobile={mobile}
          buttonsFlag="userLoginScreen"
        />
        <div className="done-password-login-wrapper">
          <DoneLoginButton
            onClick={() =>
              this.props.history.push("/DoneLoginScreen", serviceName)
            }
          />
          <div className="laundry-container">
            <div className="enter-text">
              {password_login_button_title.split("\n").map((str) => (
                <p key={str}>{str}</p>
              ))}
            </div>

            <InputFiled
              show={true}
              value={mobile}
              onChange={(e) =>
                this.onInputFieldChange("mobile", e.target.value, e)
              }
              placeholder={cellphone_input_text_hint}
              icon="mini_phone"
              onRefReady={(ref) => this.onRefReady("mobileRef", ref)}
              onFocusChanged={(isFocused) =>
                this.onInputFocusChange("mobileRef", isFocused)
              }
              displayErr={mobileErr}
              onKeyPress={(e) => this.filterEnterAndSubmit(e)}
              mandatory
              maxLength={10}
              type="tel"
              disabled={!step1_EnterPhone}
              isvalid={this.state.isMobileNumValid}
              validationtext={validationtext.mobile}
              autoFocus
            />
            <DisEnbPendBtn
              show={step1_EnterPhone}
              onClick={() => this.checkExistUser()}
              isDisabled={isSubmitMobileBtnDisabled}
              isPending={isLoadingRequest}
              extraClass="login-btn"
            >
              {continue_text}
            </DisEnbPendBtn>

            <div
              className="ls-frame-inner-wrapper"
              dir={dirRtl ? "rtl" : "lrt"}
            >
              <InputFiled
                show={step2_EnterPwd}
                value={pwd}
                type={inputPasswordType}
                onViewPasswordClick={(e) => this.onViewPasswordClick()}
                showEye
                onChange={(e) =>
                  this.onInputFieldChange("pwd", e.target.value, e)
                }
                placeholder={"********"}
                icon="mini_lock"
                onRefReady={(ref) => this.onRefReady("pwdRef", ref)}
                onFocusChanged={(isFocused) =>
                  this.onInputFocusChange("pwdRef", isFocused)
                }
                displayErr={pwdErr}
                onKeyPress={(e) => this.filterEnterAndSubmit(e)}
                mandatory
                isvalid={!this.state.isSubmitBtnDisabled}
                dirRtl={dirRtl}
                ref={(input) => this.setState({ pwdRef: input })}
                autoFocus
              />
            </div>
            <DisEnbPendBtn
              show={step2_EnterPwd}
              onClick={(e) => this.onSignInClick()}
              isDisabled={isSubmitBtnDisabled}
              isPending={isLoadingRequest}
              extraClass="login-btn"
            >
              {continue_text}
            </DisEnbPendBtn>

            <VirtualKeyboard
              currentValue={this.state[activeInputName]}
              onKeyboardInputChange={(txt) => this.onKeyboardTextChange(txt)}
              show={true}
              lang={activeInputName === "mobile" ? "mobile" : "en"}
              closeOnEnter
              blockLangSwitch={inputPasswordType}
            />
          </div>
        </div>

        <ErrorInfoModal
          dirRtl={dirRtl}
          show={showErrorModal && errTitle && errText}
          title={errTitle}
          text={errText}
          onModalCloseClick={() => this.setState({ showErrorModal: false })}
          closeTrnsl={close_text}
        />

        <ErrorInfoModal
          dirRtl={dirRtl}
          show={showBaseErrorModal}
          title={base_error_title}
          text={base_error_text}
          onModalCloseClick={() => this.setState({ showBaseErrorModal: false })}
          closeTrnsl={close_text}
        />

        <YesNoInfoModal
          show={showPhoneNotFoundModal}
          title={""}
          text={phone_number_not_found_text}
          onModalOkClick={(e) => this.props.history.push("SignUpStep1Screen")}
          onModalCancelClick={(e) =>
            this.setState({ showPhoneNotFoundModal: false })
          }
          okTransl={create_new_user_btn_text}
          cancelTransl={close_text}
          dirRtl={this.props.dirRtl}
        />
        <FixedFooter history={history} />
      </div>
    )
  }
}

const mapStateToProps = ({
  userLogin,
  bgService,
  translate,
  stationServiceTypes,
  registration,
  lockersCells,
}) => {
  const { stationId } = bgService
  const { maxLockerNumber, brokenLockersNumArr } = lockersCells
  const {
    authenticated,
    errTitle,
    errText,
    isLoadingRequest,
    isPasswordTemporary,
    isRefillDetails,
    userId,
    isError,
  } = userLogin
  const {
    dirRtl,
    langName,
    welcome_title_text,
    cellphone_input_text_hint,
    register_btn_text,
    password_input_text_hint,
    continue_text,
    password_for_delivery,
    pick_const_pwd_msg_title,
    pick_const_pwd_msg_body,
    close_text,
    back_btn_text,
    system_updgrade_err_msg,
    login_laundry,
    input_phone_title,
    validation_empty_text,
    validation_mobile_text,
    validation_password_text,
    validation_name_text,
    validation_email_text,
    validation_email_verification_text,
    input_psw_title,
    input_temp_psw_title,
    phone_number_not_found,
    create_new_user_btn_text,
    base_error_title,
    base_error_text,
    password_login_button_title,
  } = translate

  let validationtext = {
    empty: validation_empty_text,
    mobile: validation_mobile_text,
    password: validation_password_text,
    name: validation_name_text,
    email: validation_email_text,
    emailVerification: validation_email_verification_text,
  }

  const { isDeliverySimulationStation } = stationServiceTypes
  const { mobileChecked, userMobileFree } = registration
  return {
    authenticated,
    errTitle: translate[errTitle],
    errText: translate[errText],
    isLoadingRequest,
    isPasswordTemporary,
    userId,
    stationId,
    isError,
    dirRtl,
    langName,
    maxLockerNumber,
    brokenLockersNumArr,
    welcome_title_text,
    cellphone_input_text_hint,
    password_input_text_hint,
    continue_text,
    password_for_delivery,
    register_btn_text,
    pick_const_pwd_msg_title,
    pick_const_pwd_msg_body,
    back_btn_text,
    close_text,
    system_updgrade_err_msg,
    login_laundry,
    input_phone_title,
    validationtext,
    input_psw_title,
    input_temp_psw_title,
    phone_number_not_found,
    create_new_user_btn_text,
    base_error_title,
    base_error_text,
    isDeliverySimulationStation,
    mobileChecked,
    userMobileFree,
    isRefillDetails,
    password_login_button_title,
  }
}

export default connect(mapStateToProps, {
  onUserSignInClick,
  readStationIdFromBgService,
  readBgServiceConfiguration,
  resetAllReducers,
  getAllOpenedLockers,
  changeDisplayedLanguage,
  fetchAllLockersStatuses,
  fetchAllLockersLayout,
  reportSuspiciousBrokenLockers,
  logScreenEnterance,
  checkIfMobileAlreadyExists,
})(UserLoginWithDoneLoginScreen)
