import axios from "axios"
import api from "../api"

import {
  saveResultInBgServiceKeyValue,
  readResultFromBgServiceKeyValue,
  ENABLE_BG_SYNC,
  axiosGetRetry,
  axiosPutRetry,
  axiosPostRetry,
} from "../utils"

import {
  FETCH_ALL_LOCKERS_STATUSES_SUCCESS,
  ON_DRIVER_ORDER_COLLECTION_REQ_SUCCESS,
  ON_DRIVER_ORDER_COLLECTION_REQ_FAIL,
  ON_DRIVER_RETURNES_CLEAN_ORDER_REQ_SUCCESS,
  ON_DRIVER_RETURNES_CLEAN_ORDER_REQ_FAIL,
  ON_CLOSE_ORDER_REQ_SUCCESS,
  ON_CLOSE_ORDER_REQ_FAIL,
  FETCH_ALL_LOCKERS_LAYOUT_SUCCESS,
  FIND_NEXT_AVAILABLE_CELL,
  SET_CHOSEN_CELL,
  CLEAN_PUT_TO_LOCKER_ERROR,
  CREATE_RETURN_SHIPING_PACKAGE_ORDER_SUCCESS,
  CREATE_RETURN_SHIPING_PACKAGE_ORDER_FAIL,
  ON_DRIVER_RETURNES_CLEAN_ORDER_CANCEL_SUCCESS,
  ON_DRIVER_RETURNES_CLEAN_ORDER_CANCEL_FAIL,
  CLEAN_CANCEL_PUT_TO_LOCKER_ERROR,
} from "./types"

import { reportFBEvent } from "./FBEventsActions"
import { onGetReadyOrders } from "./ReadyOrdersActions"

export const fetchAllLockersStatuses = (stationId, isNeedAccessibility) => {
  return (dispatch) => {
    axiosGetRetry(api.getLockersStatusesByStationId(stationId))
      .then((res) => {
        dispatch({
          type: FETCH_ALL_LOCKERS_STATUSES_SUCCESS,
          payload: {
            data: res.data,
            isNeedAccessibility,
            serviceType: "laundry",
          },
        })

        if (ENABLE_BG_SYNC) {
          saveResultInBgServiceKeyValue(
            api.getLockersStatusesByStationId(stationId),
            res.data
          )
        }
      })
      .catch((err) => {
        console.log(err)

        if (ENABLE_BG_SYNC) {
          readResultFromBgServiceKeyValue(
            api.getLockersStatusesByStationId(stationId)
          )
            .then((savedVal) => {
              dispatch({
                type: FETCH_ALL_LOCKERS_STATUSES_SUCCESS,
                payload: { data: savedVal, isNeedAccessibility },
              })
            })
            .catch((err) => console.log(err))
        }
      })
  }
}

export const fetchLockersStatusesForRent = (
  stationId,
  type,
  isNeedAccessibility
) => {
  let url = api.getLockersForRent(stationId)
  if (type === "shipingPackage") {
    url = api.getLockersForShiping(stationId)
  }

  return (dispatch) => {
    axiosGetRetry(url)
      .then((res) => {
        dispatch({
          type: FETCH_ALL_LOCKERS_STATUSES_SUCCESS,
          payload: { data: res.data, isNeedAccessibility },
        })

        if (ENABLE_BG_SYNC) {
          saveResultInBgServiceKeyValue(url, res.data)
        }
      })
      .catch((err) => {
        console.log(err)

        if (ENABLE_BG_SYNC) {
          readResultFromBgServiceKeyValue(url)
            .then((savedVal) => {
              dispatch({
                type: FETCH_ALL_LOCKERS_STATUSES_SUCCESS,
                payload: { data: savedVal, isNeedAccessibility },
              })
            })
            .catch((err) => console.log(err))
        }
      })
  }
}

export const fetchAllLockersLayout = (stationId) => {
  return (dispatch) => {
    axiosGetRetry(api.getLockersLayoutByStationId(stationId))
      .then((res) => {
        const { layout } = res.data

        dispatch({
          type: FETCH_ALL_LOCKERS_LAYOUT_SUCCESS,
          payload: JSON.parse(layout),
          // payload: arr, //for testing different layouts
        })

        if (ENABLE_BG_SYNC) {
          saveResultInBgServiceKeyValue(
            api.getLockersLayoutByStationId(stationId),
            res.data
          )
        }
      })
      .catch((err) => {
        console.log(err)

        if (ENABLE_BG_SYNC) {
          readResultFromBgServiceKeyValue(
            api.getLockersLayoutByStationId(stationId)
          )
            .then((savedVal) => {
              const { layout } = savedVal

              dispatch({
                type: FETCH_ALL_LOCKERS_LAYOUT_SUCCESS,
                payload: JSON.parse(layout),
              })
            })
            .catch((err) => console.log(err))
        }
        // USE WHEN OFFLINE..!
        // dispatch({
        //     type: FETCH_ALL_LOCKERS_LAYOUT_SUCCESS,
        //     payload: arr
        // });
      })

    // updateLayout();
  }
}

// FLORINTIN LAYOUT
const arr = [
  [1, 3, 5, 7, 9, 11, 13, 15, 17, 19, 21, 23, -2, 25, 33, 41, 49, 57, 65],
  [1, 3, 5, 7, 9, 11, 13, 15, 17, 19, 21, 23, -2, 26, 34, 42, 50, 58, 66],
  [1, 3, 5, 7, 9, 11, 13, 15, 17, 19, 21, 23, -2, 27, 35, 43, 51, 59, 67],
  [1, 3, 5, 7, 9, 11, 13, 15, 17, 19, 21, 23, -2, 28, 36, 44, 52, 60, 68],
  [2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, -1, 29, 37, 45, 53, 61, 69],
  [2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, -1, 30, 38, 46, 54, 62, 70],
  [2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, -1, 31, 39, 47, 55, 63, 71],
  [2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, -1, 32, 40, 48, 56, 64, 72],
]

// const arr = [
//   [1, 2, 3, 4, -2, 9, 10],
//   [1, 2, 3, 4, -2, 9, 10],
//   [1, 2, 3, 4, -2, 11, 12],
//   [1, 2, 3, 4, -2, 13, 14],
//   [1, 2, 3, 4, -2, 15, 16],
//   [5, 6, 7, 8, -1, 17, 18],
//   [5, 6, 7, 8, -1, 19, 20],
//   [5, 6, 7, 8, -1, 21, 22],
//   [5, 6, 7, 8, -1, 23, 24],
//   [5, 6, 7, 8, -1, 23, 24],
// ]

const updateLayout = (stationId) => {
  axios
    .post(
      api.setLockersLayoutByStationId(stationId),
      `"${JSON.stringify(arr)}"`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {})
    .catch((err) => console.log(err))
}

export const setChosenCell = (cellNum) => {
  return {
    type: SET_CHOSEN_CELL,
    payload: cellNum,
  }
}

export const findNextAvailableCell = () => {
  return {
    type: FIND_NEXT_AVAILABLE_CELL,
  }
}

export const onDriverCollectsOrder = (
  orderId,
  lockerId,
  stationId,
  orderBarcode
) => {
  return (dispatch) => {
    axiosPutRetry(api.onDriverCollectsOrder(orderId, lockerId, orderBarcode))
      .then((res) => {
        dispatch({
          type: ON_DRIVER_ORDER_COLLECTION_REQ_SUCCESS,
        })
        dispatch(fetchAllLockersStatuses(stationId))
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: ON_DRIVER_ORDER_COLLECTION_REQ_FAIL,
        })
      })
  }
}

export const onDriverReturnesCleanOrder = (orderId, lockerId, stationId) => {
  return (dispatch) => {
    axiosPutRetry(api.onDriverReturnesCleanOrder(orderId, lockerId))
      .then((res) => {
        dispatch({
          type: ON_DRIVER_RETURNES_CLEAN_ORDER_REQ_SUCCESS,
        })
        console.log("Driver returnes CleanOrder successfully. order:" + orderId)
        dispatch(onGetReadyOrders(stationId))
        dispatch(fetchAllLockersStatuses(stationId))
      })
      .catch((err) => {
        console.log("ERROR IN ACTION!!!!", err)
        dispatch({
          type: ON_DRIVER_RETURNES_CLEAN_ORDER_REQ_FAIL,
        })
      })
  }
}

export const onDriverReturnesCleanOrderCancel = (
  orderId,
  lockerId,
  stationId
) => {
  return (dispatch) => {
    axiosPutRetry(api.onDriverReturnesCleanOrderCancel(orderId, lockerId))
      .then((res) => {
        dispatch({
          type: ON_DRIVER_RETURNES_CLEAN_ORDER_CANCEL_SUCCESS,
        })
        console.log("Driver returnes CleanOrder CANCELED. order:" + orderId)
        dispatch(onGetReadyOrders(stationId))
        dispatch(fetchAllLockersStatuses(stationId))
      })
      .catch((err) => {
        console.log("ERROR IN ACTION!!!!", err)
        dispatch({
          type: ON_DRIVER_RETURNES_CLEAN_ORDER_CANCEL_FAIL,
        })
      })
  }
}

export const cleanPutToLockerError = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAN_PUT_TO_LOCKER_ERROR,
    })
  }
}

export const cleanCancelPutToLockerError = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAN_CANCEL_PUT_TO_LOCKER_ERROR,
    })
  }
}

export const onCloseOrder = (orderId, lockerId) => {
  return (dispatch) => {
    axiosPutRetry(api.onCloseOrder(orderId, lockerId))
      .then((res) => {
        dispatch({
          type: ON_CLOSE_ORDER_REQ_SUCCESS,
        })

        dispatch(
          reportFBEvent(
            "Order_Closed",
            "An Existing Order Was Collected By A User!"
          )
        )
      })
      .catch((err) => {
        console.log("onCloseOrder > " + err)
        dispatch({
          type: ON_CLOSE_ORDER_REQ_FAIL,
        })
      })
  }
}

export const reportSuspiciousBrokenLockers = (
  stationId,
  suspiciousLockersArr
) => {
  return (dispatch) => {
    axios
      .post(api.reportSuspiciousBrokenLockers(stationId), suspiciousLockersArr)
      .catch((err) => console.log(err))
  }
}

export const returnPackageOrder = (
  orderId,
  lockerId,
  stationId,
  lockerNum,
  optionalWrongDoor
) => {
  return (dispatch) => {
    axiosPostRetry(
      api.returnPackageOrder(orderId, lockerId) +
        "&optionalWrongDoor=" +
        optionalWrongDoor
    )
      .then((res) => {
        dispatch({
          type: CREATE_RETURN_SHIPING_PACKAGE_ORDER_SUCCESS,
          payload: { ...res.data, lockerNum: lockerNum },
          //payload: { ...res.data, lockerNum: data.lockerNum },
        })
        dispatch(
          reportFBEvent(
            "ReturnPackage Order",
            "ReturnPackage Order oneProject Has Been Created! "
          )
        )
      })
      .catch((err) => {
        console.log("ERROR IN ACTION!!!!", err)
        dispatch({
          type: CREATE_RETURN_SHIPING_PACKAGE_ORDER_FAIL,
        })
      })
  }
}
